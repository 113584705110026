.adminCont {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background-color: #d0ddf9;
  .navBar {
    background-color: white;
    border-radius: 0 0 10px 0;
    min-width: 250px;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 250px;
    }
    p {
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2b3677;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 25px;
      color: #2b3677;
      font-weight: 700;
    }
    .logoutBtn {
      position: absolute;
      top: 5%;
      right: 5%;
      transition: 0.2s ease;
      border: 1px solid #2b3677;
      background-color: #2b3677;
      color: white;
      padding: 5px;
      width: 100px;
      border-radius: 10px;
      &:hover {
        background-color: transparent;
        border: 1px solid #2b3677;
        color: #2b3677;
        transition: 0.2s ease;
      }
    }
    .invoices {
      width: 95%;
      min-height: 490px;
      max-height: 490px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      .header {
        background-color: #2b3677;
        height: 50px;
        width: 100%;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        flex-direction: row;
        border: 4px solid #2b3677;
        border-radius: 10px 10px 0 0;
        p {
          color: white;
          font-size: 14px;
          font-weight: 300;
          &:nth-child(1) {
            width: 12%;
            margin-left: 50px;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 6%;
            margin-left: 10px;
          }
          &:nth-child(4) {
            width: 15%;
            margin-left: 70px;
          }
          &:nth-child(5) {
            width: 7%;
            margin-left: 20px;
          }
          &:nth-child(6) {
            width: 10%;
            margin-left: 30px;
          }
          &:nth-child(7) {
            width: 9%;
            margin-left: 60px;
          }
        }
      }
      .data {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: auto;
        .singleData {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;
          p {
            font-size: 14px;
            font-weight: 300;
            text-align: left;
            &:nth-child(1) {
              width: 12%;
              margin-left: 40px;
            }
            &:nth-child(3) {
              width: 6%;
              margin-left: -5px;
            }
            &:nth-child(4) {
              width: 15%;
              margin-left: 20px;
            }
            &:nth-child(5) {
              width: 7%;
              margin-left: 70px;
            }
            &:nth-child(6) {
              width: 7%;
              margin-left: 55px;
            }
            &:nth-child(7) {
              width: 9%;
              margin-left: 80px;
            }
          }
          a {
            width: 10%;
            margin-left: 20px;
            text-align: left;
          }
          .approveBtn {
            width: 6%;
            background-color: #2b3677;
            border-radius: 10px;
            height: 30px;
            color: white;
            font-size: 14px;
            margin-right: -20px;
          }
          .approved {
            color: #3d8736;
          }
        }
        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #bdbbbb;
          border-radius: 10px;
        }
      }
    }
  }
}
