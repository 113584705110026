.card{
    background-color: #cfdcf8;
    padding: 60px;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    margin: 100px auto;
    .subscribe{
        display: flex;
        justify-content: center;
        width: 100%;
        .error{
            position: absolute;
            font-size: 12px;
            color: rgba(255, 0, 0, 0.509);
            padding-left: 12px;
            padding-top: 3px;
        }
        .inputs{
            width: 38%;
        }
    }
    h1{
        color: #2B3677;
        margin-bottom: 10px;
        margin-top: 0;
    }
    p{
        color: #2B3677;
        max-width: 450px;
        text-align: center;
    }
    .inputHolder{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        input{
            max-width: 350px;
            width: 100%;
            padding: 8px 10px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: none;
        }
        button{
            border-top-left-radius: 0px;
            padding: 7.5px 18px;
            border-bottom-left-radius: 0px;
            font-weight: normal;
            font-size: 15px;
        }
    }
}

@media(max-width: 991px){
    .card {
        padding: 50px 30px;
        margin: 0px auto;
    h1{
        font-size: 22px;
        margin-bottom: 0;
        text-align: center;
    }
    p{
        font-size: 14px;
        margin-bottom: 25px;
    }
  .inputHolder{
    button{
        padding: 7.5px 10px;
        font-size: 12px;
    }
  }
    }
}

@media(max-width: 767px){
    .card{
        .subscribe{
            .inputs{
                width: 52%;
            }
        }
    }
}

@media(max-width: 575px){
    .card{
        .subscribe{
            .inputs{
                width: 82%;
            }
        }
    }
}