@media(max-width: 991px){
    .navbar{
        position: relative;
    }
    .showPassword{
        display: none !important;
    }
    .signupPage{
        width: 100% !important;
        background-color: transparent !important;
    }
.backgroundsignup{
    z-index: -1;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    background-image: url(../assets/images/background.png);
    background-size: cover;
    background-position: center;
    img{
        display: none;
    }

}
.signupHolder{
    width: 100% !important;
    left: 100px !important;
    top: 100px !important;
}
}

@media(max-width: 767px){
    .inputsHolder{
        width: 100% !important;
    }
    .inputMobile{
        width: 100% !important;
        .input{
            width: 100% !important;
            max-width: 300px !important;
            input{
                max-width: 300% !important;
                width: 100% !important;
            }
    }
    }
    .switchsignup{
        left: 290px !important;
        top: 25px !important;
    }
    .headersignup{
        height: 200px !important;
    }
    .logosignup{
        width: 20px !important;
        left: 30px !important;
        top: 80px !important;
    }
    .textData{
        display: none !important;
        // width: 70% !important;
        max-width: 400px !important;
        width: 100% !important;
        left: 0 !important;
        margin-left: 10px !important;
    
    }
    
    .signupHolder{
        width: 100% !important;
        left: 0 !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    
    .updated{
        margin-left: 25px !important;
        font-size: 20px !important;
    }
    .joinsign{
        margin-left:25px !important;
        font-size: 20px !important;
    }
    .agree{
        font-size: 13px !important;
    }
    .holdeer{
        margin-left: 20px !important;
    }
    .anyinfo{
        // width: 322px !important;
    }
    .signupPage{
        height: 1200px !important;
    }

    .input{
        input{
            font-size: 12px;
            // width: 322px !important;
        }
    }
    .dropdown{
        // width: 61% !important;
        p{
            font-size: 12px !important ;

        }
        .options{
            top: 37px !important;
            p{
                font-size: 12px !important;
            }
        }
    }
    .inputsHolder{
        flex-direction: column !important;
    }
    .container1{
        width: 100vw !important;
    }
    .stepstitle{
        font-size: 20px;
    }
    .benefits{
        margin-top: 0 !important;
        display: none !important;
        margin-bottom: 0 !important;
    }
    .casestudiesTitle{
        left: 30px !important;
        max-width: 400px !important;
    }
    .faqcall{
        margin-top: 30px !important;
    }
    .faqchat{
        margin-top: 30px !important;
    }
    .faqanswer{
       font-size: 16px !important;
    }
    .faqText{
        font-size: 13px !important;
        width: 100%;
        left: 0 !important;
        padding-right: 10px !important;
    }
    .faqHolder{
        margin-top: 10px !important;
        width: 100% !important;

    }
    .accordion{
        max-width: 400px !important;
        width: 100% !important;
        p{
            font-size: 12px !important;
        }
    }
    .faq{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
            font-size: 20px !important;
        }
    }
    .casestudies .container1 .content .box{
        max-width: 385px !important;
    }
    .caseparagraph{
        max-width: 300px !important;
    }
    .listitems{
        font-size: 12px !important;
        max-width: 320px !important;
    }
    .possibilitiesTitle{
        line-height: 30px !important;
        left: 0 !important;
        font-size: 25px !important;
        max-width: 350px !important;
    }
    .possibilitiesHolder{
        width: 100vw !important;
    }
    .textInvestor{
        display: none !important;
    }
    .numbersInvestor{
        display: none !important
    }
    .navbar{
        margin-top: 0 !important;
        width: 100%;
    }
    .headerContainer{
        margin-top: 0 !important;
        width: 100vw !important;
    }
    .backCompanies{
        width: 100vw !important;
    }
    .investorsContainer{
        width: 100vw !important;
    }
    .investordesc{
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .profiles{
        margin-top: 100px !important;
    }
    .text{
        left: 0 !important;
        width: 100% !important;
        justify-content: flex-start !important;
        padding-bottom: 10px !important;
    }
    .dashboardcompany{
        left: 35px !important;
    }
    .companyTitle{
        padding-left: 25px !important;
        padding-right: 25px !important;
        left: 0 !important;
        p{
            text-align: left !important;
            width: 100% !important;
        }
        .textCompany{
           margin: -40px 0 0 0 !important;
            text-align: left !important;
            font-size: 20px !important;
        }
    }
    .companiesWrapper{
        margin-top: -150px !important;
        width: 100vw !important;
    }
    .trustedbydesc{
        margin-left: 25px !important;
        width: 100% !important;
        left: 0 !important;
        padding-right: 20px !important;
        div{
            width: 100% !important;
            padding-right: 20px !important;
        }
    }
    .headerContainer{
        height: 820px !important;
        background-position: right !important;
    }
    .dashboardimg{
        width: 120% !important;
        max-width: 120% !important;
        margin-top: -60px;
        // left: 0 !important;
    }
    .logosignup{
        display: none !important;
    }
    .goBack{
        left: 10px;
    }
    .bannerTitle{
        top: -50px !important;
        font-weight: bolder !important;
        font-size: 33px !important;
        line-height: 40px !important;
        max-width: 370px;
        text-align: left !important;
        width: 146% !important;
        left: 0 !important;
    }
    .descriptionBanner{
        top: 0 !important;
        margin-top: 410px !important;
        font-size: 16px !important;
        line-height: 23px !important;
        max-width: 600px;
        text-align: left !important;
        width: 126% !important;
        left: 0 !important;
    }
}


@media(max-width: 576px){
    .signupPage{
        left: 0 !important;
    }
    .signupHolder{
        left: 10px !important;
    }
    .inputsHolder{
        width: 100% !important;
    }
    .inputMobile{
        width: 100% !important;
        .input{
            width: 100% !important;
            max-width: 318px !important;
            input{
                max-width: 318px !important;
                width: 100% !important;
            }
    }
    .fullWidthInput{
        input{
            width: 318px !important;
        }
    }
    }

}

@media(max-width: 1199px){

}

@media(max-width: 1399px){

}

@media(max-width: 1599px){
}

