.container{
    padding: 50px 180px 100px 180px;
    h1{
        text-align: center;
        font-size: 40px;
        color: #0B0F19;
        font-family: "Lato-Black";
        line-height: 46px;
        margin-bottom: 0;
    }
    p{
        text-align: center;
        color: #9397AD;
        font-size: 18px;
    }
    .cards{
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media(max-width: 1499px){
    .container{
        padding: 70px 100px;
    }
}

@media(max-width: 1299px){
    .container{
        padding: 30px 0px;
        h1{
            font-size: 30px;
        }
        p{
            margin-top: 0;
            font-size: 16px;
        }
        h3{
            font-size: 18px;
            margin-bottom: 8px;
        }
    }
}

@media(max-width: 991px){
    .container{
        padding-bottom: 0;
        h1{
            font-size: 25px;
        }
        p{
            font-size: 15px;
        }
        .cards{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
