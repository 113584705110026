.companydashboard {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #d0ddf9;
  .companytable {
    align-self: center;
    justify-self: center;
    background-color: white;
    padding: 15px 40px;
    border-radius: 8px;
    .bloc-tabs {
      display: flex;
      margin-bottom: 10px;
    }
    .tabs {
      padding: 15px;
      text-align: center;
      width: 50%;
      // background: rgba(128, 128, 128, 0.075);
      cursor: pointer;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.274);
      border-bottom: 1px solid #d0ddf9;
      box-sizing: content-box;
      position: relative;
      outline: none;
    }
    .tabs {
      &:not(&:last-child) {
        // border-right: 1px solid rgba(0, 0, 0, 0.274);
        border-right: 1px solid #d0ddf9;
      }
    }
    .active-tabs {
      background: white;
      border-bottom: 1px solid transparent;
    }
    .active-tabs {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 5px;
        background: rgb(88, 147, 241);
      }
    }
    .content {
      display: none;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        overflow-x: auto;
      }
    }
    .active-content {
      display: block;
    }
    .invoices {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      h3 {
        color: #2b3677;
        font-size: 15px;
      }
      button {
        font-size: 14px;
        font-weight: normal;
      }
    }
    table {
      width: 100%;
      thead {
        text-align: left;
        th {
          padding-bottom: 10px;
          cursor: pointer;
          font-size: 12px;
          color: #618ce8be;
          svg {
            margin-left: 3px;
            width: 8px;
          }
        }
      }
      tbody {
        td {
          white-space: pre-wrap;
          padding-right: 90px;
          padding-bottom: 10px;
          font-size: 12px;
          color: #919ca7;
          &:last-child {
            padding-bottom: 0;
            padding-right: 23px;
          }
          span {
            min-width: 50px;
            padding: 4px;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .containerHeader {
    margin: 80px auto;
    padding: 50px;
    .dashboardButtons {
      display: grid;
      gap: 630px;
      grid-template-columns: 1fr 1fr;
      h2 {
        font-size: 24px;
        font-weight: bold;
        color: #2b3677;
      }
      .buttonsHolder {
        display: flex;
        align-items: center;
        gap: 20px;
        button {
          padding: 5px 20px;
          font-size: 15px;
          svg {
            width: 18px;
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
}

.dashContainer {
  background-color: white;
  padding: 10px 50px;
  @media (max-width: 991px) {
    padding: 10px;
  }
  .dashboardnav {
    padding-left: 80px;
    padding-right: 80px;
    grid-template-columns: 1fr 1fr;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
    }
    .dashLinks {
      display: flex;
      gap: 40px;
      // @media (max-width: 575px) {
      //   div {
      //     &:first-child {
      //       display: none !important;
      //       svg {
      //         display: none !important;
      //       }
      //     }
      //   }
      // }
      .headerLogo {
        @media screen and (max-width: 767px) {
          display: block;
        }
      }
      @media (max-width: 575px) {
        gap: 15px;
      }
      a {
        display: flex;
        align-items: center;
        margin-top: 6px;
        color: #2b3677;
        font-size: 14px;
        svg {
          margin-right: 8px;
        }
        @media (max-width: 575px) {
          font-size: 12px;
          svg {
            display: none;
          }
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        a {
          &:nth-child(2) {
            margin-left: -15px;
          }
        }
      }
    }
    .profilelinks {
      .profileinfo {
        display: flex;
        align-items: center;
        .infouser {
          margin-left: 10px;
          h3 {
            color: #2b3677;
            font-size: 13px;
            margin-bottom: 0;
          }
          p {
            color: #2b3677;
            margin-top: -2px;
            font-size: 11px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .hamburger {
      display: none;
      @media screen and (max-width: 767px) {
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          width: 30px;
          height: 5px;
          background-color: #2b3677;
          margin-bottom: 5px;
          border-radius: 10px;
          transition: 0.3s ease;
        }
      }
    }
  }
  .dashboardnav-expand {
    background-color: #2b3677;
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: 100;
    .dashLinks {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 60px;
      a {
        color: white;
        font-size: 20px;
        margin-top: 20px;
        &:nth-child(5) {
          margin-left: 25px;
        }
        &:nth-child(6) {
          margin-left: 25px;
        }
      }
    }
    .profilelinks {
      margin-top: -320px;
      margin-left: 5px;
      padding: 5px;
      .profileinfo {
        display: flex;
        align-items: center;
        .infouser {
          margin-left: 10px;
          h3 {
            color: white;
            font-size: 13px;
            margin-bottom: 0;
          }
          p {
            color: white;
            margin-top: -2px;
            font-size: 11px;
          }
        }
      }
    }
    .hamburgerX {
      @media screen and (max-width: 767px) {
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 3%;
        left: 89%;
        span {
          width: 30px;
          height: 5px;
          background-color: white;
          border-radius: 10px;
          &:nth-child(1) {
            transform: rotate(-45deg);
            margin-bottom: -5px;
            transition: 0.3s ease;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
            transition: 0.3s ease;
          }
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}

//laptop
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .companydashboard {
    .containerHeader {
      margin: 80px auto;
      padding: 50px;
      .dashboardButtons {
        display: grid;
        gap: 500px;
        grid-template-columns: 1fr 1fr;
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #2b3677;
        }
        .buttonsHolder {
          display: flex;
          align-items: center;
          gap: 20px;
          button {
            padding: 5px 20px;
            font-size: 15px;
            svg {
              width: 18px;
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}
//tablet
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .companydashboard {
    overflow-y: scroll;
    .containerHeader {
      width: 100%;
      padding: 20px;
      .dashboardButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #2b3677;
        }
        .buttonsHolder {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -600px;
          margin-bottom: 40px;
          button {
            padding: 5px 20px;
            font-size: 15px;
            svg {
              width: 18px;
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}
//mobile
@media screen and (max-width: 767px) {
  .companydashboard {
    overflow-y: scroll;
    .containerHeader {
      width: 100%;
      padding: 20px;
      .dashboardButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: -50px;
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #2b3677;
        }
        .buttonsHolder {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-top: -600px;
          margin-bottom: 40px;
          button {
            padding: 5px 20px;
            font-size: 15px;
            svg {
              width: 18px;
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
    .companytable {
      padding: 15px 30px;
      margin-bottom: 20px;
      .bloc-tabs {
      }
      .tabs {
      }
      .tabs {
        &:not(&:last-child) {
        }
      }
      .active-tabs {
      }
      .active-tabs {
        &::before {
          content: "";
        }
      }
      .content {
        overflow-x: scroll;
      }
      .active-content {
        overflow-x: scroll;
      }
      .invoices {
        h3 {
        }
        button {
          &:nth-child(2) {
            padding: 0;
          }
        }
      }
      thead {
        th {
        }
      }
    }
    tbody {
      td {
        &:last-child {
        }
        span {
        }
      }
    }
  }
}

@media (min-width: 2200px) {
  .dashContainer {
    padding: 20px 70px;
    .dashboardnav {
      .dashLinks {
        .headerLogo {
          transform: scale(1.2);
          margin-top: 5px;
          margin-right: 50px;
        }
        a {
          font-size: 20px;
          svg {
            margin-right: 20px;
            transform: scale(1.2);
          }
        }
      }
      .profilelinks {
        .profileinfo {
          transform: scale(1.2);
        }
      }
    }
  }
  .dragdesc {
    svg {
      transform: scale(1.2);
      margin-bottom: 10px;
    }
    font-size: 23px !important;
  }
}

@media (min-width: 2900px) {
  .dashContainer {
    padding: 30px 70px;
    .dashboardnav {
      .dashLinks {
        .headerLogo {
          margin-top: 10px;
        }
        a {
          font-size: 25px;
          svg {
            margin-right: 20px;
            transform: scale(1.3);
          }
        }
      }
      .profilelinks {
        .profileinfo {
          transform: scale(1.4);
        }
      }
    }
  }
  .dragdesc {
    svg {
      transform: scale(1.5);
    }
    font-size: 30px !important;
  }
}

.langlist {
  display: flex;
  gap: 10px;
  list-style: none;
  margin-right: 15px;
  li {
    color: #2b3677;
    &:hover {
      font-weight: bold;
    }
  }
}
