.container{
    max-width: 350px;
    padding: 30px 15px;
    box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
    border-radius: 8px;
    position: relative;
    .icon{
        position: absolute;
        top: -25px;
        left: 0;
    }
    p{
        color: #52556B;
    }
}

.imgHolder{
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    img{
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    .name{
        display: flex;
        flex-direction: column;
        h4{
            margin-bottom: 0;
            margin-top: 0;
            font-size: 14px;
        }
        span{
            font-size: 12px;
            color: #9397AD;
        }
    }
}

@media(max-width: 991px){
    .container{
        p{
            font-size: 12px;
        }
    }
    .imgHolder{
        img{
            width: 40px;
            height: 40px;
        }
    }
}

@media(max-width: 767px){
    .container{
        .icon{
            transform: scale(0.7);
        }
    }
}