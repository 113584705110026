.user-profile {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  //   height: 100vh;
  width: 100vw;
  background-color: #d0ddf9;
  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    width: 90%;
    padding: 15px 40px;
    border-radius: 8px;
    margin-bottom: -0.5%;
    h2 {
      color: #2b3677;
      font-size: 24px;
    }
    .infoSection {
      background-color: #2b3677;
      width: 100%;
      padding: 15px 40px;
      border-radius: 8px;
      display: flex;
      .profilePic {
        background-color: white;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        padding: 10px;
        align-self: center;
        img {
          width: 100%;
          border-radius: 50%;
          width: 130px;
          height: 130px;
          object-fit: cover;
        }
      }
      .userInfo {
        display: flex;
        flex-direction: column;
        color: #fff;
        width: 35%;
        margin-left: 50px;
        .userName {
          justify-self: flex-start;
          p {
            font-size: 18px;
            &:nth-child(2) {
              font-size: 13px;
              opacity: 0.5;
            }
          }
        }
        .userData {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 90%;
          @media (max-width: 1450px) {
            width: 110%;
          }
          .revenue {
            width: 200px;
            p {
              font-size: 15px;
              &:nth-child(2) {
                font-size: 13px;
                opacity: 0.5;
              }
            }
          }
          .uploads {
            p {
              font-size: 15px;
              &:nth-child(2) {
                width: 150px;
                font-size: 13px;
                opacity: 0.5;
              }
            }
          }
        }
      }
      .buttonContainer {
        display: flex;
        justify-content: end;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        top: 0%;
        .editButton {
          transition: 0.2s ease;
          height: 40px;
          font-size: 13px;
          img {
            width: 15px;
            margin-right: 10px;
            opacity: 0.5;
          }
          &:hover {
            transition: 0.2s ease;
            box-shadow: 5px 5px 15px 1px rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
  .fieldsContainer {
    display: flex;
    justify-self: center;
    align-self: center;
    width: 90%;
    padding: 15px 40px;
    border-radius: 8px;
    justify-content: space-between;

    .companyInfo {
      padding: 15px 40px;
      border-radius: 8px;
      background-color: white;
      width: 39%;
      h3 {
        color: #2b3677;
        font-size: 15px;
        margin-bottom: 20px;
      }
      p {
        font-size: 13px;
        color: #98a6ad;
        margin-bottom: 20px;
      }
      .infos {
        display: flex;
        margin-bottom: -5px;
        p {
          color: #98a6ad;
          font-weight: 700;
          &:nth-child(2) {
            font-weight: 400;
            margin-left: 20px;
          }
        }
      }
    }
    .otherInfo {
      padding: 15px 40px;
      border-radius: 8px;
      background-color: white;
      width: 59%;
      h3 {
        color: #2b3677;
        font-size: 15px;
        margin-bottom: 20px;
      }
      .addAccountContainer {
        margin-top: 50px;
        border: 3px solid #618be8;
        border-style: dashed;
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 100px;
          margin-bottom: 20px;
        }
        p {
          font-size: 20px;
          color: #618be8;
          font-weight: 700;
        }
      }
      .fieldGroup {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .fields {
          width: 48%;
          .selector {
            outline: none;
            font-family: "Lato";
            padding-left: 10px;
            padding-right: 10px;
            border: none;
            width: 100%;
            height: 42px;
            display: block;
            gap: unset;
            border: 1px solid rgba(212, 215, 229, 1);
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 1);
            color: #ababab;
            margin-bottom: 20px;
            margin-right: 5px;
            option {
              color: black;
            }
            .input-error {
              margin-left: 5px;
              margin-bottom: -10px;
              color: #f04343 !important;
              text-align: left;
            }
          }
          .addAccount {
            margin-top: -45px;
          }
          p {
            color: #9397ad;
          }
          .inputField {
            margin-bottom: 20px;
            input {
              width: 100%;
            }
            &:nth-last-child(1) {
              margin-top: 13px;
            }
          }
          #inputFieldUst {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="30"><text x="5" y="19" style="font: bold 16px Arial;">DE | </text></svg>')
              no-repeat;
            background-position: 5px 7.5px;
            text-indent: 35px;
          }
          .addButton {
            margin-bottom: 20px;
          }
          button {
            width: 100%;
            height: 40px;
            justify-content: center;
            font-weight: 500;
            img {
              width: 20px;
            }
          }
          .afterEditButton {
            width: 47%;
          }
          .accInfo {
            display: flex;
            flex-direction: column;
            .infoSection {
              display: flex;
              padding: 5px;
              justify-content: space-between;
              .innerSection {
                width: 100%;
                span {
                  color: #9397ad;
                  width: 200px;
                }
                .infoField {
                  .label {
                    font-size: 12px;
                    color: #618be8;
                    margin-bottom: -20px;
                  }
                  p {
                    font-size: 16px;
                    color: #2b3677;
                    margin-bottom: 35px;
                  }
                }
              }
            }
          }
        }
      }
      .confirmButton {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.date-input-error {
  margin-top: -20px;
  margin-bottom: -10px !important;
  width: max-content;
  font-size: 12px;
  margin-left: 5px;
  color: #ef4444a3 !important;
  text-align: left;
}
.gender-input-error {
  margin-top: -20px;
  margin-bottom: -10px;
  width: max-content;
  font-size: 12px;
  margin-left: 5px;
  color: #ef4444a3 !important;
  text-align: left;
}

@media (max-width: 1000px) {
  .user-profile {
    .fieldsContainer {
      flex-direction: column;
      .companyInfo {
        width: 100%;
        margin-bottom: 20px;
      }
      .otherInfo {
        width: 100%;
      }
    }
    .innerContainer {
      .infoSection {
        .profilePic {
          width: 130px;
          height: 130px;
          img {
            width: 110px;
            height: 107px;
          }
        }
        .userInfo {
          .userData {
            gap: 18px;
            .revenue {
              padding-right: 20px;
              &:first-child {
                p {
                  white-space: nowrap;
                }
              }
            }
            .uploads {
              p {
                &:nth-child(2) {
                  width: 127px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .user-profile {
    .fieldsContainer {
      width: 100%;
      .otherInfo {
        .fieldGroup {
          .fields {
            .selector {
              font-size: 11px;
            }
            .addAccount {
              font-size: 11px;
            }
          }
        }
      }
    }
    .innerContainer {
      width: 100%;
      .infoSection {
        padding: 15px 20px;
        .profilePic {
          width: 110px;
          height: 110px;
          img {
            width: 91px;
            height: 91px;
          }
        }
        .userInfo {
          width: 100%;
          .userData {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
          }
        }
      }
    }
  }
}

.genderselector {
  padding-bottom: 20px !important;
}

.input-errordatee {
  width: max-content;
  color: #ef4444a3 !important;
  text-align: left;
  font-size: 12px !important;
  font-weight: lighter !important;
  margin-top: -20px !important;
  // margin-bottom: 10px !important;
  margin-left: 5px;
}
