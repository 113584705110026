.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    z-index: 1;
    background-color: white;
    .image{
        position: relative;
        max-width: 310px;
        width: 100%;
        height: 320px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        z-index: 1;
        .content{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            background: #618ce865;
            width: 100%;
            height: 0;
            border-radius: 8px;
            transition: all ease-in-out .4s;
            z-index: -1;
            .socials{
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                svg{
                    height: 0;
                }
            }
        }
        &:hover{
           .content{
            height: 100%;
            transition: all ease-in-out .4s;
            svg{
                height: auto;
            }
           }
        }
    }
    p{
        font-weight: bold;
        margin-bottom: 10px;
    }
    span{
        font-size: 14px;
        color: #565973;
    }
    &:nth-child(2){
        .image{
           &:hover{
            .content{
                display: none;
            }
           }
        }
    }
}

@media(max-width: 575px){
    .container{
        .image{
            max-width: 90%;
        }
    }
}

@media(max-width: 400px){
    .container{
        .image{
            max-width: 100%;
        }
    }
}