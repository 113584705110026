.banner {
  min-height: 70vh;
  background-color: #2b3677;
  padding: 20px 40px 0px 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .bannerHolder {
    display: grid;
    gap: 30px;
    margin: 0 auto;
    grid-template-columns: 1fr 0.9fr;
    max-width: 80vw;
    .content {
      color: white;
      .btns{
        display: flex;
        gap: 20px;
    }
      .scroll{
        margin-top: 100px;
        display: flex;
        align-items: center;
      }
      .title {
        font-size: 70px;
        line-height: 80px;
        margin-top: 100px;
        font-family: "Lato-Black";
      }
      .desc {
        font-size: 17px;
        line-height: 22px;
        max-width: 430px;
      }
      button {
        margin-top: 30px;
        border: 1.5px solid #2b3677;
        transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;

        &:hover {
          background-color: #2b3677 !important;
          border: 1.5px solid rgb(97, 139, 232);
        }
      }

      p {
        margin-bottom: 50px;
        cursor: pointer;
        color: white;
        .title{
            font-size: 68px;
            line-height: 80px;
            margin-top: 100px;
            font-family: "Lato-Black";
        }
        .desc{
            font-size: 17px;
            line-height: 22px;
            max-width: 430px;
        }
        button{
            margin-top: 30px;
            border: 1.5px solid #2B3677;
            transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;
        
            &:hover{
                background-color: #2B3677 !important;
                border: 1.5px solid rgb(97, 139, 232);
            }
        }
          
        p{
            margin-bottom: 50px;
            cursor: pointer;
            color: white;
            margin-top: 50px;
            display: flex;
            align-items: center;
            svg{
                width: 50px;
                margin-top: 5px;
                margin-right: 5px;
                &:hover{
                    g{
                        rect{
                            fill: rgb(97, 139, 232);
                        }
                        path{
                            fill: white;
                        }
                    }
                }
            }
            &.desc{
                margin-bottom: 0;
            }
        }
    }
    .img{
        display: flex;
        align-items: center;
        svg {
          width: 50px;
          margin-top: 5px;
          margin-right: 5px;
          &:hover {
            g {
              rect {
                fill: rgb(97, 139, 232);
              }
              path {
                fill: white;
              }
            }
          }
        }
        &.desc {
          margin-bottom: 0;
        }
      }
    }
    .img {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      img {
        position: absolute;
        margin-right: -150px;
        width: 900px;
        bottom: -100px;
        @media (max-width: 1299px) {
          width: 700px;
          margin-right: -100px;
        }
      }
    }
  }
}

@media (max-width: 1399px) {
  .banner {
    .bannerHolder {
      max-width: 85vw;
      .content {
        .title {
          font-size: 50px;
          line-height: 60px;
        }
      }
    }
  }
}

@media(max-width: 1199px){
    .banner{
        min-height: 50vh;
    }
}

@media(max-width: 991px){
    .banner{
        min-height: 57vh;
        .bannerHolder{
            grid-template-columns: 1fr;
            .content{
                display: flex;
                align-items: center;
                flex-direction: column;
                .scroll{
                  margin-top: 50px;
                }
                .title{
                    margin-top: 0;
                    max-width: 600px;
                    text-align: center;
                }
                .desc{
                    margin-top: 0;
                    text-align: center;
                    max-width: 600px;
                }
                p{
                    margin-bottom: 0;
                }
            }
            .img{
                img{
                    position: relative;
                    width: 100%;
                    bottom: 0;
                    margin-right: 0;
                }
            }
        }
        .desc {
          margin-top: 0;
          text-align: center;
          max-width: 600px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .img {
        img {
          position: relative;
          width: 100%;
          bottom: 0;
          margin-right: 0;
        }
      }
    }

@media (max-width: 767px) {
  .banner {
    .bannerHolder {
      .content {
        .desc {
          margin-top: 5px;
        }
        p {
          margin-top: 30px;
        }
        .title {
          font-size: 35px;
          line-height: 40px;
        }
        button {
          margin-top: 40px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .banner {
    padding: 20px 30px;
    .bannerHolder {
      max-width: 100vw;
    }
  }
}

button{
    position: relative;
}

.companyBanner{
    background-image: url(../../../assets/images/Header.png);
    background-size: cover;
    background-position: center;
    background-color: transparent;
    min-height: 79vh;
    .bannerHolder{
        .content{
            padding-bottom: 50px;
            .title{
                margin-top: 50px;
                color: #2B3677;
                margin-bottom: 25px;
            }
            .desc{
                margin-top: 0;
                color: #2B3677;
            }
        }
        .img{
            justify-content: center;
            align-items: center;
            img{
                position: relative;
                bottom: 0;
                margin-right: -200px;
            }
        }
        .content{
            .btns{
                button{
                &:first-child{
                    border: 1.5px solid rgb(97, 139, 232);
                    &:hover{
                        background-color: transparent !important;
                        border: 1.5px solid rgb(97, 139, 232);
                        color: #2B3677;
                    }
                }
                &:nth-child(2){
                    border: 1.5px solid #2B3677;
                    &:hover{
                        background-color: transparent !important;
                        border: 1.5px solid #2B3677;
                        color: #2B3677;
                    }
                }
            }
            }
        }
    }
    @media(max-width: 1599px){
        .bannerHolder{
            .content{
                .title{
                    font-size: 50px;
                    line-height: 62px;
                }
            }
        }
    }
    @media(max-width: 1099px){
        .bannerHolder{
            .content{
                .title{
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        }
    }
    @media(max-width: 991px){
        .bannerHolder{
           .img{
            img{
                margin-right: -150px;
            }
           }
        }
    }
    @media(max-width: 575px){
        .bannerHolder{
           .img{
            img{
                width: 110%;
                margin-right: -70px;
            }
           }
        }
    }
}
