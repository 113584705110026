@import url(../assets/fonts.scss);

body,
html {
  font-family: "Lato";
  overflow-x: hidden !important;
}
.jobspage {
  h1 {
    color: #2b3677;
    margin-top: 50px;
    text-align: center;
  }
  .navbar {
    background-color: #cedbf7;
    a {
      display: none;
    }
    .lang {
      top: 18px !important;
    }
  }
}
.cardBack {
  &:hover {
    background: rgba(97, 139, 232, 0.3);
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in;
  }
}
.imprintHolder {
  padding-top: 10px;
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  background-color: #cfdcf8;
  .imprintContent {
    padding: 40px;
    text-align: left;
    max-width: 43rem;
    margin: 0 auto;
    color: rgb(43, 54, 119);
    a {
      text-decoration: none;
      color: rgb(43, 54, 119);
    }
  }
  .iTitle {
    margin-top: 0px;
  }
}
.cardBack {
  &:hover {
    background: rgba(97, 139, 232, 0.3);
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in;
  }
}
.headerContainer {
  background-image: url(../assets/images/Header.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.navbar {
  background-color: #cedbf7;
  position: fixed;
  z-index: 9;
}

.dropdownContainer {
  font-family: "Lato";
  label {
    color: #2b3677;
    font-size: 14px;
    padding-bottom: 5px;
  }
}
.langbtn {
  background: none;
  border: none;
}
.banner {
  margin-top: 0 !important;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown {
  height: 42px;
  cursor: pointer;
  border: 1px solid rgba(212, 215, 229, 1);
  display: flex;
  background-color: rgba(255, 255, 255, 1);
  padding-left: 10px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  svg {
    margin-right: 15px;
    margin-top: 5px;
    &.active {
      transform: rotate(-180deg);
    }
  }
  p {
    margin-top: 8px;
    margin-bottom: 0;
    cursor: pointer;
    color: #979797;
    font-size: 15px;
    text-align: left;
    display: flex;
    svg {
      margin-left: 5px;
      margin-top: 0;
      width: 13px;
      height: 13px;
      border-radius: 100%;
    }
  }
  .options {
    left: -1px;
    top: 35px;
    background-color: rgba(255, 255, 255, 1);
    border-right: 1px solid rgba(212, 215, 229, 1);
    border-left: 1px solid rgba(212, 215, 229, 1);
    border-bottom: 1px solid rgba(212, 215, 229, 1);
    width: 100.4%;
    position: absolute;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 1;
    height: 120px;
    overflow: auto;
    p {
      font-size: 15px;
      padding-left: 10px;
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    > div {
      svg {
        margin-left: 13px;
        border-radius: 100%;
        max-width: 20px;
      }
    }
  }
}

input {
  outline: none;
}

.logo1 {
  width: 20%;
  margin-top: -35px;
}
.input {
  display: flex;
  flex-direction: column;
  border: none;
  label {
    color: #2b3677;
    font-size: 14px;
    margin-bottom: 5px;
  }
  input {
    position: relative;
    font-family: "Lato";
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    width: 100%;
    height: 100%;
    width: 251px;
    height: 42px;
    display: block;
    gap: unset;
    border: 1px solid rgba(212, 215, 229, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    &::placeholder {
      color: #ababab;
    }
  }
  .input-error {
    width: max-content;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: -10px;
    color: #ef4444a3 !important;
    text-align: left;
  }
}

.fullWidth {
  input {
    width: 100%;
  }
}
.logosshade {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
}
.input-signup {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  > div {
    margin-top: 100px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-top: 0;
    }
  }
}
a {
  text-decoration: none;
}
.faqlink {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: none;
}
.title {
  font-size: 32px !important;
  font-family: "Lato";
  font-weight: bold;
}

.greenlink {
  svg {
    path {
      fill: #22c55e;
    }
  }
}
.faqbuttons {
  display: flex;
  gap: 30px;
}

.logos {
  position: relative;
  margin: 100px auto;
  /* display: flex;
    align-items: center;
    gap: 100px; */
  /* height: 140.5px; */
  max-width: 1296px;
  @media (max-width: 1199px) {
    margin: 50px auto;
  }
  @media (max-width: 767px) {
    margin: 45px auto 10px auto;
  }
}
.logos .logo {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  width: 59% !important;
  height: auto;
  margin-top: 0;
}
.logos .logo1 {
  width: 22% !important;
  margin-top: 0;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.logos {
  position: relative;
}

.amplify-input:focus {
  box-shadow: none;
}

.activefaq {
  transform: rotate(-180deg);
}
// .dashboardimg {
//     @media (max-width: 991px) {
//         display: none;
//     }
// }

.faq {
  display: grid;
  grid-template-columns: 5fr 10fr;
  gap: 120px;
  .title {
    h1 {
      text-align: left;
    }
  }
  .accordions {
    padding-left: 80px;
    .accordion {
      cursor: pointer;
      margin-bottom: 20px;
      background-color: white;
      position: relative;
      p {
        cursor: initial;
        display: flex;
        align-self: flex-start;
        text-align: left;
        color: black;
      }
    }
  }
}
.stepscontainer {
  display: flex;
  flex-direction: column;
}
.steps {
  margin-top: 70px;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  gap: 100px;
}

.round-slider-container {
  width: 62px;
  height: 31px;
  background-color: #2b3677;
  border-radius: 50px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;
  cursor: pointer !important;
}
.forinvestors {
  position: absolute;
  min-width: 150px;
  left: 82px;
}
.stepstitle {
  margin: 0 auto;
  margin-bottom: 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 130%;
  color: #0b0f19 !important;
}
.caseparagraph {
  margin-top: 20px;
  margin-bottom: 20px;
}

.activetab {
  color: #2b3677;
  font-weight: bold;
}

.forcompanies {
  position: absolute;
  min-width: 150px;
  left: -139px;
}

.switchcontainer {
  margin: 0 auto;
}

.round-slider {
  width: 24px;
  height: 23px;
  border-radius: 50%;
  background-color: white;
  top: 4px;
  left: 4px;
  position: absolute;
}

#checkbox-input:checked + .round-slider-container .round-slider {
  transform: translateX(30px);
}
#checkbox-input {
  visibility: hidden;
}
.imprint {
  cursor: pointer;
}

@media (max-width: 991px) {
  .wrapper {
  }
}
.investorimg {
  width: 100%;
  height: auto;
}

.button {
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 9px 30px;
  border-radius: 4px;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  svg {
    margin-left: 10px;
  }
}
.closebtn {
  position: absolute;
  z-index: 2;
  color: rgb(108, 108, 108);
  right: 0;
  top: 10px;
}

.submitbtn {
  margin: 0 auto;
  border-radius: 50px;
  margin-top: 10px;
}

button,
a {
  cursor: pointer;
}

button {
  background: none;
  border: none;
  outline: none;
}

.banner {
  @media (max-width: 991px) {
    position: relative !important;
    margin: 0 auto !important;
    left: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    .paragraph {
      top: 200px !important;
    }
  }
}
.shade {
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.826);
  width: 100vw;
  height: 100vh;
}

.waitbtn {
  margin: 20px auto;
  border-radius: 50%;
}

.casestudies {
  position: relative;
  width: 100%;
  .desc {
    height: 90px;
    h2 {
      position: absolute;
      width: 228px;
      height: 52px;
      left: 312px;
      top: 0px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 130%;
      color: #0b0f19;
    }
    p {
      text-align: left;
      position: absolute;
      width: 636px;
      height: 58px;
      left: 312px;
      top: 76px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      color: #9397ad;
    }
    button {
      padding: 13px 32px;
      gap: 8px;
      background-color: transparent;
      position: absolute;
      min-width: 199px;
      height: 52px;
      left: 1393px;
      top: 73px;
      border: 1px solid rgba(99, 102, 241, 0.35);
      border-radius: 8px;
      color: #2b3677;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
    }
  }
  .container1 {
    background-image: url(../assets/images/casebackground.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
    position: relative;
    width: 100%;
    z-index: 0;

    @media (max-width: 767px) {
      height: 550px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.429);
      z-index: -1;
    }

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 1;
      margin-left: 20%;
      @media (max-width: 767px) {
        padding-right: 10px;
        margin-left: 5%;
      }

      .box {
        margin-top: 160px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        min-height: 350px;
        max-width: 500px;
        width: 100%;
        background-color: white;
        padding: 35px;
        @media (max-width: 767px) {
          margin-top: 90px;
        }

        h4 {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 10px;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }

        span {
          color: gray;
          font-size: 14px;
        }

        p {
          margin-bottom: 20px;
          margin-top: 30px;
          color: #565973;
          @media (max-width: 767px) {
            max-width: 80%;
            width: 100%;
          }
        }

        .studybtn {
          height: 44px;
          width: 156px;
          left: 40px;
          top: 336px;
          border-radius: 6px;
          background: #2b3677;
          color: white;
          border: none;
          margin-top: 15px;
          font-family: "Lato";
          cursor: pointer;
        }
      }
    }
  }
}

.submitContainer {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .submitContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-weight: bold;
      margin-bottom: 30px;
    }
    p {
      max-width: 500px;
      text-align: center;
      margin-top: 30px;
      color: #2b3677;
      margin-bottom: 40px;
    }
  }
  .backSubmit {
    width: 100%;
    height: 100%;
    background-image: url(../assets/images/background.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.casestudiesbtn {
  border: 1px solid rgba(99, 102, 241, 0.35);
}

@media (max-width: 1400px) {
  .faq {
    gap: 0;
    .accordions {
      padding-left: 35px;
    }
  }
}

@media (max-width: 1215px) {
  .faq {
    gap: 0;
    grid-template-columns: 1fr;
    .accordions {
      padding-left: 0px;
    }
    .faqbuttons {
      display: none;
    }
  }
}

.modalBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  .modalBody {
    width: 250px;
    height: 250px;
    background-color: white;
    position: absolute;
    border-radius: 10px;
    // top: 61%;
    // left: 81%;
    top: 30%;
    left: 40%;
    .closeButton {
      width: 100%;
      text-align: right;
      padding: 5px;
      cursor: pointer;
      span {
        margin-right: 5px;
      }
    }
    .modalData {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: -10px;
      img {
        width: 200px;
      }
      p {
        font-size: 12px !important;
        color: #2b3677;
        text-align: center;
        margin-top: -5px;
        &:nth-child(3) {
          width: 200px;
        }
      }
      button {
        font-size: 12px;
        border-radius: 20px;
        height: 35px;
        margin-top: 10px;
      }
    }
  }
}

.settings-content {
  position: absolute;
  width: 200px;
  background-color: #fff;
  border: 1px solid #2b3677;
  border-radius: 10px 10px;
  padding: 5px;
  height: 85px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  right: 3%;
  text-align: center;
  a {
    text-decoration: none;
    color: #2b3677;
    margin-top: 10px;
    &:hover {
      background-color: #e5e5e5;
    }
  }
  button {
    text-decoration: none;
    color: #2b3677;
    margin-top: 10px;
    &:hover {
      background-color: #e5e5e5;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    margin-right: 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-right: -20px;
  }
}
form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fullWidth {
  max-width: 400px;
  width: 100%;
}
.error {
  color: rgba(255, 0, 0, 0.616);
  font-size: 12px;
  text-align: left;
  max-width: 400px;
  width: 100%;
}
.goBack {
  display: flex;
  align-items: center;
  position: absolute;
  top: 22px;
  margin-top: 0;
  left: 110px;
  font-size: 13px;
  color: #2b3677;
  svg {
    transform: translateX(-2px);
    transition: 0.1s ease-in-out;
  }
  &:hover {
    svg {
      transform: translateX(-7px);
      transition: 0.1s ease-in-out;
    }
  }
}
.inputMobile {
  width: 100%;
}
.fullWidthInput {
  width: 100%;
  input {
    width: 100%;
  }
}
.recipientInput {
  width: 100%;
  input {
    width: 100%;
  }
  @media (max-width: 991px) {
    max-width: 370px;
    width: 100%;
  }
}
.company-recipientInput {
  width: 370px;
  input {
    width: 100%;
  }
  @media (max-width: 991px) {
    max-width: 370px;
    width: 100%;
  }
}

.loginn {
  margin-top: 0 !important;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101323b8;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  .shades {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999999;
    background-color: transparent;
  }
}

.loginInput {
  label {
    color: #2b3677;
    margin-bottom: 5px;
  }
  input {
    font-size: 16px;
    width: 400px;
    max-width: 100%;
    height: 52px;
    color: #676767;
    border: 1.5px solid #d4d7e5;
    padding: 17px 10px;
    &:focus-visible {
      outline: 2px solid #2b3677c2;
    }
  }
  span {
    top: -10px;
    color: #b4b7c9;
  }
}

.loginbtn {
  padding: 9px 90px;
}
.closebtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
  font-size: 22px;
}

.filled {
  span {
    margin-top: -6px;
    font-size: 12px;
  }
  input {
    &:focus {
      margin-top: 0px;
    }
  }
}
@media (max-width: 767px) {
  .mobileiIcon {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 9999999999 !important;
  }
  .menu-open {
    position: absolute;
    top: 0;
    transform: translateX(60%);
    width: 220px;
    min-height: 265px;
    background-color: #cddafa;
    transition: all 1s ease !important;
    ul {
      color: black;
      margin-top: 80px;
      list-style: none;
      li {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 15px;
        color: #2b3677;
      }
    }
  }
  .menu-closed {
    transform: translateX(100%);
    transition: all 1s ease !important;
  }
  .closemenu {
    font-size: 18px;
    top: 10px;
    font-weight: bold;
    color: #2b3677;
  }
}

.passwordSignup {
  input {
    width: 525px;
  }
}

@media (min-width: 768px) {
  .mobileiIcon {
    display: none;
  }
}

.fullWidthInput {
  position: relative;
  input {
    width: 525px;
  }
}

.eyebtn {
  position: absolute;
  top: 43px;
  right: 8px;
  svg {
    width: 12px;
    height: auto;
    g {
      fill: #6c757ddf;
    }
    path {
      stroke: #6c757ddf;
    }
  }
}
.fullWidth {
  max-width: 400px;
  width: 100%;
  label {
    margin-bottom: 10px;
  }
  input {
    width: 100%;
  }
}
form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.goBack {
  display: flex;
  align-items: center;
  position: absolute;
  top: 35px;
  margin-top: 0;
  left: 20px;
  font-size: 13px;
  color: #2b3677;
  svg {
    transform: translateX(-2px);
    transition: 0.1s ease-in-out;
  }
  &:hover {
    svg {
      transform: translateX(-7px);
      transition: 0.1s ease-in-out;
    }
  }
}

//signup style

.signupWrapper {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .signupBackground {
    background-image: url(../assets/images/background.png);
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .signupInputs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .allInputs {
      margin-top: 100px;
      padding-bottom: 50px;
      .passwordHolder {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        div {
          input {
            width: 100%;
          }
        }
      }
    }
    .rowInputs {
      align-items: flex-start;
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      &.checkboxText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .error {
          margin-top: -20px;
        }
      }
      .checkboxHolder {
        display: flex;
        align-items: flex-start;
        label{
          margin-top: 5px;
        }
        p {
          max-width: 500px;
          padding-left: 10px;
          color: rgba(85, 85, 85, 0.666);
          margin-top: 0;
        }
      }
    }
    .signupDropdown {
      width: 520px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .navSignup {
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-self: flex-start;
      ul {
        display: flex;
        list-style: none;
        margin-right: 20px;
        li {
          cursor: pointer;
          font-family: "Lato";
          font-size: 16px;
          font-weight: 700;
          color: rgb(16, 19, 35);
          margin-left: 10px;
          margin-right: 10px;
          &:nth-child(2) {
            margin-left: 2px;
            margin-right: 2px;
          }
        }
      }
      svg {
        max-width: 75%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1399px) {
  .signupWrapper {
    .signupInputs {
      .goBack {
        top: 5px;
        font-size: 12px;
      }
      .navSignup {
        margin-top: 30px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .signupWrapper {
    grid-template-columns: 1fr 0.6fr;
  }
}

@media (max-width: 991px) {
  .signupWrapper {
    grid-template-columns: 1fr;
    padding-bottom: 30px;
    position: relative;
    .signupBackground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
      height: 100%;
      z-index: -1;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
      }
    }
    .signupInputs {
      .navSignup {
        padding-left: 25px;
        padding-right: 25px;
        margin-top: 30px;
        justify-content: space-between;
        svg {
          width: auto;
        }
        ul {
          margin-left: 70px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .signupWrapper {
    .signupInputs {
      .rowInputs {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        div {
          div {
            input {
              width: 300px;
            }
          }
        }
      }
      .signupDropdown {
        width: 300px;
        div {
          label {
            font-size: 13px;
            color: #2b3677;
          }
          div {
            width: 300px;
          }
        }
      }
      .allInputs {
        margin-top: 50px;
        h1 {
          font-size: 20px;
        }
        padding-left: 50px;
        .passwordHolder {
          div {
            input {
              width: 300px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .signupWrapper {
    .signupInputs {
      .allInputs {
        padding-left: 20px;
      }
    }
  }
}
.verify {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    label {
      color: #2b3677;
      font-size: 20px;
    }
  }
  button {
    margin-top: 30px;
  }
}

.add-recipient-button {
  font-size: 14px;
  color: #2b3677;
  border-radius: 15px;
  transition: 0.2s ease;
  line-height: 15px;
  width: fit-content !important;
  // margin-bottom: 5px;
  // width: 30px !important;
  // height: 30px !important;
  &:hover {
    color: #2b3677;
    background-color: transparent;
  }
}

.spinnerBody {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    width: 100px;
    height: 100px;
    border: 20px solid transparent;
    border-top: 20px solid #2b3677;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.positionTitle {
  font-family: "Lato-Black";
}

@media (max-width: 991px) {
  .positionTitle {
    font-size: 25px;
  }
}

.profileInput {
  label {
    font-weight: 500;
    font-size: 16px;
    color: #0b0f19;
  }
}

.addressInput {
  margin-top: 20px;
  width: 100%;
  max-width: 61%;
  input {
    width: 100%;
    margin-bottom: 25px;
  }
}
.addressInput1 {
  width: 100%;
  max-width: 61%;
  input {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .addressInput,
  .addressInput1 {
    max-width: 96%;
  }
}

.checkboxInput {
  width: 15px;
  input {
    width: 15px;
  }
}

.paymentInput {
  margin-bottom: 20px;
  width: 100%;
  input {
    width: 100%;
  }
}

.options{
  p{
  &:hover{
    background-color: #ccdaf96c;
  }
}
}

.inputTitle{
  width: 100%;
  font-size: 14px;
  color: #2b3677;
}


.forgotpsw{
  margin: 20px auto 30px auto;
}

.alreadyacc{
  color: #98A6AD;
  font-size: 15px;
  a{
  font-weight: bold;
  }
}

.buttonsPart{
  display: flex;
  align-items: center;
  gap: 10px;
}