.container{
    background-color: white;
    max-width: 500px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    padding: 0px 30px 25px 30px; 
    z-index: 2;
    h2{
        color: #2b3677;
    }
    .select{
        width: 100%;
    .options{
        width: 100%;
        display: flex;
        margin-top: 5px;
        gap: 20px;
        span{
            font-size: 25px;
            color: rgba(128, 128, 128, 0.715);
            padding: 0 20px;
            font-weight: lighter;
        }
    }
}
    .btns{
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
        button{
            font-size: 14px;
            font-weight: 500;
            padding: 7px 19px;
        }
    }
}
