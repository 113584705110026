
.nopage{
    min-height: 100vh;
    background-color: #F3F6FF;
    .contentHolder{
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 0.8fr;
        max-width: 1150px;
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
    h1{
        margin-top: 0;
        font-size: 96px;
        color: #0B0F19;
        margin-bottom: 0;
    }
    p{
        margin-top: 0;
        font-size: 24px;
        color: #565973;
        max-width: 500px;
    }
    button{
        padding: 12px 27px;
        margin-top: 30px;
        border-radius: 8px;
        width: fit-content;
        svg{
            margin-left: 0;
            margin-right: 10px;
        }
    }
    }
    .imgHolder{
        img{
            margin-top: 100px;
        }
    }
}
}