.investorDashboard {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-color: #d0ddf9;
  .topElements {
    display: grid;
    gap: 630px;
    grid-template-columns: 1fr 1fr;
    margin: 20px auto;
    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #2b3677;
    }
    .buttonsHolder {
      display: flex;
      align-items: center;
      gap: 20px;
      input {
        color: white;
        background-color: #618be8;
        width: 230px;
        height: 35px;
      }
      button {
        //   padding: 5px 20px;
        margin: 0 auto;
        text-align: center;
        height: 35px;
        width: 20px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 18px;
        }
      }
    }
  }
  .mainContainer {
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 100px;
    .secondSection {
      display: grid;
      grid-template-columns: 7fr 4fr;
      .countriesPart {
        margin-top: 25px;
        margin-left: 25px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        .optionsHolder {
          background-color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 14px;
            color: #2b3677;
            font-weight: 700;
          }
        }
        .countryList {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-height: 220px;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }
          .element {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: -20px;
            margin-bottom: -30px;
            &:nth-child(1) {
              margin-top: -15px;
            }
            .state {
              color: #919ca7;
              font-size: 15px;
              font-weight: 400;
            }
            .countryBar {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-left: -50px;
              margin-top: -50px;
              .progressLabel {
                padding: 5;
                color: "white";
                font-weight: "bold";
              }
              .indicator {
                color: #919ca7;
              }
            }
          }
        }
      }
      // .leftPart {
      //   width: 68.5%;
      //   padding: 5px 40px;
      //   border-radius: 8px;
      // }
      // .rightPart {
      //   width: 30.5%;
      //   border-radius: 8px;
      // }
    }
  }
}
