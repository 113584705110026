.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .number{
        background-color: #F3F6FF;
        padding: 10px 10px;
        border-radius: 50%;
        h1{
            padding: 13px 18px;
            background-color: white;
            border-radius: 50%;
            margin-top: 0;
            margin-bottom: 0;
            line-height: 24px;
            font-size: 24px;
            box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
        }
    }
    h3{
        font-family: "Lato-Black";
        text-align: center;
        color: #0B0F19;
        font-weight: 600;
        font-size: 24px;
        margin: 20px 0 15px 0;
    }
    p{
        margin-top: 0;
        margin-bottom: 0;
        color: #565973;
        font-size: 16px;
        text-align: center;
        max-width: 270px;
    }
    .circle{
        position: relative;
        background-color: #618ce838;;
        padding: 12px 12px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            position: absolute;
            padding: 7px 7px;
            background-color: #618BE8;
            border-radius: 50px;
        }
        &::after{
            content: "";
            position: absolute;
            right: -330px;
            top: 10px;
            width: 290px;
            background-color: #E2E5F1;
            height: 2px;
            @media(max-width: 1499px){
                right: -215px;
                width: 200px;
            }
            @media(max-width: 1099px){
                right: -190px;
                width: 175px;
            }
            @media(max-width: 991px){
                content: none;
            }
        }
    }
}

@media(max-width: 1199px){
    .container{
        .number{
            h1{
                font-size: 20px;
            }
        }
        h3{
            font-size: 18px;
        }
        p{
            font-size: 14px;
        }
    }
}

@media(max-width: 991px){
    .container{
        margin-bottom: 10px;
        .number{
            h1{
                font-size: 18px;
            }
        }
        h3{
            margin: 10px 0;
        }
    }
}
@media(max-width: 575px){
    .container{
        h3{
            margin: 10px 0;
            font-size: 16px;
        }
        p{
            font-size: 13px;
        }
    }
}