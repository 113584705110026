.firstsection{
    display: grid;
    grid-template-columns: 6fr 7fr;
    gap: 20px;
    .investorcards{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
    .profileHolder{
        border-radius: 5px;
        padding: 20px;
        background-color: white;
        width: 100%;
        .investorinfo{
            display: flex;
            justify-content: space-between;
            .information{
                margin-top: 20px;
                span{
                    color: #2B3677;
                    font-size: 12px;
                    padding-left: 10px;
                    position: relative;
                    &::before{
                        content:"• ";
                        position: absolute;
                        top: -2px;
                        left: 0;
                        color:#22C55E;
                    }
                }
                p{
                    margin-top: 7px;
                    color: #B4B7C9;
                    font-size: 16px;
                    margin-bottom: 0;
                }
                h2{
                    margin-top: 0;
                    color: #2B3677;
                    font-size: 24px;
                    font-weight: bold;
                }
            }
            .profileinfo{
                display: flex;
                align-items: flex-start;
                img{
                margin-top: 5px;
                margin-right: 10px;
                }
                h3{
                    color: #2B3677;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                p{
                    color: #2b3677a3;
                    font-size: 12px;
                   margin-bottom: 0;
                   margin-top: 0;
                }
            }
        }
        .balanceTitle{
            color: #98A6AD;
            margin-bottom: 10px;
        }
        .balanceItem{
            display: flex;
            align-items: center;
            svg{
                width: 50px;
                height: auto;
                margin-right: 15px;
                margin-top: 18px;
            }
            p{
                margin-bottom: 0px;
                color: #919CA7;
                font-size: 20px;
                font-weight: bold;
            }
            .balanceAmount{
                margin-left: 40px;
            }
            &:nth-child(5){
                margin-left: 5px;
                svg{
                    width: 35px;
                }
                p{
                    margin-left: 10px;
                }
                .balanceAmount{
                    margin-left: 50px;
                }
            }
        }
    }
}
.investorbox{
    background-color: white;
    padding: 20px 25px;
    max-width: 350px;
    border-radius: 5px;
    .firstrow{
        display: flex;
        justify-content: space-between;
        .boxtitle{
            color: #98A6AD;
            font-size: 15px;
        }
    }
    .amount{
        margin-top: 7px;
        margin-bottom: 20px;
        color: #919CA7;
        font-size: 24px;
        font-weight: bold;
    }
    .time{
        font-size: 14px;
        color: #98A6AD;
        svg{
            margin-right: 5px;
        }
        .percent{
            margin-right: 15px;
            color: #42D29D;
        }
        &.red{
            .percent{
                color: red;
            }
            svg{
                transform: rotate(-180deg);
                path{
                    fill: red;
                }
            }
        }
    }
}