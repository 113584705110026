.investor {
  display: grid;
  grid-template-columns: 0.25fr 0.9fr;
  .profileNav {
    background-color: white;
    height: 100vh;
    .profile {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        max-width: 100px;
        width: 100%;
      }
      .btns {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        button {
          padding: 8px;
          font-size: 16px;
          color: #33354d;
          width: 100%;
          &.activeButton {
            padding: 8px;
            background-color: #2b3677;
            box-shadow: 0px 8px 18px -8px rgba(43, 54, 119, 0.8);
            color: white;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .content {
    background-color: #d0ddf9;
    padding-left: 70px;
    padding-bottom: 20px;
    .firstTab {
      padding-top: 20px;
    }
    h1 {
      color: #2b3677;
      font-size: 32px;
      line-height: 41px;
      font-family: "Lato-Black";
    }
    p {
      font-size: 20px;
      color: #2b3677;
      font-family: "Lato-Black";
    }
    .inputs {
      display: grid;
      grid-template-columns: 0.3fr 0.3fr;
      gap: 30px;
      margin-bottom: 25px;
      input {
        width: 100%;
      }
    }
    label {
      font-size: 16px;
      font-weight: 500;
      color: #0b0f19;
    }
    textarea {
      margin-top: 5px;
      width: 61%;
      border: none;
      resize: none;
      border-radius: 8px;
      margin-bottom: 25px;
    }
    .bio {
      display: flex;
      flex-direction: column;
    }
    .buttons {
      display: flex;
      gap: 10px;
      button {
        font-size: 14px;
        padding: 7px 20px;
        font-weight: 500;
      }
    }
    .addressSection {
      margin-top: 80px;
      margin-bottom: 20px;
    }
    .delete {
      margin-top: 50px;
      margin-bottom: 10px;
    }
    span {
      color: #565973;
      font-size: 16px;
    }
    .check {
      display: flex;
      align-items: center;
      gap: 20px;
      span {
        font-size: 14px;
        margin-top: 2px;
      }
    }
    .secondTab {
      padding-top: 20px;
      .inputs {
        margin-top: 20px;
      }
    }
  }
  .payment {
    padding-top: 20px;
  }
}
@media (max-width: 991px) {
  .investor {
    grid-template-columns: 0.4fr 0.9fr;
    .content {
      padding-left: 50px;
      padding-right: 50px;
      .inputs {
        grid-template-columns: 1fr 1fr;
      }
      textarea {
        width: 100%;
      }
    }
  }
}

@media (max-width: 830px) {
  .investor {
    grid-template-columns: 0.35fr 0.9fr;
    .content {
      padding-left: 30px;
      padding-right: 30px;
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 16px;
      }
    }
    .profileNav {
      .profile {
        img {
          max-width: 80px;
        }
        .btns {
          button {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .investor {
    grid-template-columns: 1fr;
    .profileNav {
      padding-left: 20px;
      padding-right: 30px;
      background-color: #cfddf8;
      height: auto;
      .profile {
        .btns {
          gap: 10px;
          width: 100%;
          overflow-x: scroll;
          flex-direction: row;
          button {
            white-space: nowrap;
          }
        }
      }
    }
    .content {
      min-height: 100vh;
      padding-left: 20px;
      label {
        white-space: nowrap;
      }
      .inputs {
        width: 96%;
      }
      textarea {
        width: 96%;
      }
    }
  }
}

.addmodal {
  margin-top: 0 !important;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101323b8;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  .shades {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: transparent;
  }
}

.custom-toast {
  --toastify-color-success: #0734bc;
  --toastify-icon-color-success: #0734bc;
  --toastify-color-progress-success: #0734bc;
}
