label {
  font-size: 14px;
  color: #2b3677;
  margin-top: 0;
}
.input-errordate {
  width: max-content;
  color: #ef4444a3 !important;
  text-align: left;
  font-size: 12px !important;
  font-weight: lighter !important;
  margin-top: -15px !important;
  margin-bottom: 10px !important;
  margin-left: 5px;
}

.allfields {
  margin-top: -37px;
}
.invoiceContainer {
  background-color: #cfdcf8;
  width: 100vw;
  min-height: 100vh;
  .recipient {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    .recipientdata {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .infoicon {
      position: relative;
      cursor: pointer;
      .tooltip {
        position: absolute;
        width: max-content;
        font-weight: lighter;
        top: -25px;
        right: 0;
        font-size: 10px;
        background-color: rgb(245, 245, 245);
        border-radius: 5px;
        padding: 5px;
        display: none;
        &.nospace {
          top: -15px;
        }
      }
      &:hover {
        .tooltip {
          display: block;
        }
      }
    }
    p {
      margin-top: 0;
      color: #618be8 !important;
    }
    svg {
      margin-top: 15px;
    }
  }
  .wrap {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    .invoiceshade {
      position: absolute;
      top: -78px;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: #101323cb;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      z-index: -1;
    }
    .invoicepopup {
      margin-top: 120px;
      z-index: 1;
      position: fixed;
      .popupInfo {
        padding: 30px;
        background-color: white;
        min-width: 500px;
        min-height: 300px;
        border-radius: 15px;

        .moreInfo {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-top: 30px;
          button {
            margin-top: 40px;
            border-radius: 50px;
            padding: 9px 70px;
          }
          .descInfo {
            max-width: 450px;
            margin-bottom: 10px;
          }
          p {
            align-self: flex-start;
            color: #2b3677;
            font-weight: bold;
            margin-bottom: 0;
            margin-top: 10px;
            span {
              font-size: 15px;
              font-weight: normal;
              margin-left: 10px;
            }
          }
        }
        .logoPopup {
          align-self: center;
          justify-self: center;
          display: flex;
          margin: 0 auto;
        }
      }
      .informationpopup {
        width: 400px;
        min-width: 400px;
        margin-top: -70px;
        .btns {
          display: flex;
          gap: 15px;
          button {
            border-radius: 10px;
            padding: 9px 60px;
          }
        }
      }
    }
  }
  .invoiceContent {
    padding-top: 50px;
    max-width: 85%;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    grid-template-columns: 0.5fr 0.8fr;
    column-gap: 20px;
    @media (max-width: 1499px) {
      max-width: 95%;
    }
    @media (max-width: 1199px) {
      grid-template-columns: 1fr;
      gap: 30px;
      max-width: 68%;
    }
    @media (max-width: 576px) {
      max-width: 85%;
    }
    &.multipleFiles {
      grid-template-columns: 1fr 1.6fr;
      @media (max-width: 1499px) {
        max-width: 97%;
      }
      @media (max-width: 1299px) {
        grid-template-columns: 0.5fr 1fr;
      }
      @media (max-width: 1199px) {
        grid-template-columns: 1fr;
      }
      @media (max-width: 991px) {
        grid-template-columns: 1fr;
        gap: 30px;
        max-width: 80%;
      }
      @media (max-width: 575px) {
        max-width: 90%;
      }
    }
    .pdfHolder {
      border-radius: 7px;
      .previewWrap {
        height: 750px;
      }

      .urlHolder {
        border-radius: 7px;
        border: 1px solid #618ce85d;
        height: 100%;
      }

      ::-webkit-scrollbar {
        background-color: white;
        width: 3px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #618ce85d;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #618ce898;
      }

      position: relative;

      input {
        position: absolute;
      }

      .preview {
        border: none !important;
        background-color: white;
        border-radius: 7px;
        align-items: center;
        display: flex;
        font-size: 2rem;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      #file-input {
        display: none;
      }

      .fileinput-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 300px;
        left: 125px;
        position: absolute;
        cursor: pointer;
        color: #618be8;
        font-size: 18px;
        font-weight: bold;
      }

      label[for="file-input"] * {
        vertical-align: middle;
        cursor: pointer;
      }

      label[for="file-input"] span {
        margin-left: 10px;
      }

      i.remove {
        vertical-align: middle;
        margin-left: 5px;
        cursor: pointer;
        display: none;
      }
    }
    .mainRightCont {
      width: 100%;
      .fields-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.5rem;
        .top-fields {
          border-radius: 7px;
          background-color: white;
          min-height: 50%;
          padding: 13px 30px 13px 30px;
          margin-bottom: 1.5rem;
          .bankAccInfo {
            margin-left: 5px;
            margin-top: 30px;
            p {
              font-size: 14px;
              color: #2b3677;
            }
          }
          .dropdown-error {
            margin-left: 5px;
            margin-bottom: -10px;
            margin-top: -15px;
            color: #f04343 !important;
            text-align: left;
            font-weight: 200;
          }
          .dropdown {
            .options {
              p {
                padding-top: 0;
                font-weight: normal;
                font-size: 15px;
              }
            }
          }
          p {
            color: #6c757d;
            font-size: 14px;
            font-weight: 700;
            // margin-bottom: 15px;
          }
          .invoice {
            margin-bottom: 5px;
            input {
              width: 100%;
            }
            &:nth-last-child(1) {
              margin-top: 15px;
            }
          }
        }
        .dateField {
          font-family: "Lato";
          padding-left: 10px;
          padding-right: 10px;
          border: none;
          width: 100%;
          height: 52px;
          display: block;
          gap: unset;
          border: 1px solid rgba(212, 215, 229, 1);
          border-radius: 8px;
          background-color: rgba(255, 255, 255, 1);
          margin-bottom: 15px;
          &::placeholder {
            color: #ababab;
          }
        }
      }
      .rightBottomCont {
        width: 100%;
        .bottom-fields {
          border-radius: 7px;
          background-color: white;
          min-height: 67%;
          padding: 13px 30px 13px 30px;
          margin-bottom: 9px;
          p {
            color: #6c757d;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 8px;
          }
          .invoice {
            margin-bottom: 12px;
            input {
              width: 100%;
            }
            &:nth-last-child(1) {
              margin-top: 15px;
            }
          }
          .selector {
            outline: none;
            font-family: "Lato";
            padding-left: 10px;
            padding-right: 10px;
            border: none;
            width: 100%;
            height: 42px;
            display: block;
            gap: unset;
            border: 1px solid rgba(212, 215, 229, 1);
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 1);
            color: #ababab;
            margin-bottom: 15px;
            option {
              color: black;
            }
            .input-error {
              margin-left: 5px;
              margin-bottom: -10px;
              color: #f04343 !important;
              text-align: left;
            }
          }
        }
        .button-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 1.5rem;
          .subButton {
            display: flex;
            justify-content: center;
          }
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
aside {
  div {
    padding: 0;
    width: 100% !important;
    height: 100% !important;
    margin-right: 0;
    border: none !important;
  }
}
.pdfframe {
  width: 32vw !important;
  @media (max-width: 1499px) {
    width: 36vw !important;
  }
  @media (max-width: 1199px) {
    width: 96vw !important;
  }
  @media (max-width: 991px) {
    width: 78vw !important;
  }
  @media (max-width: 767px) {
    width: 86vw !important;
  }
}
.dropzone {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // z-index: 2;
}
.containerPdf {
  background-color: white;
  border-radius: 7px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 82vh;
  cursor: pointer;
  @media (max-width: 1199px) {
    min-height: 60vh;
  }
  @media (max-width: 1000px) {
    min-height: 59vh;
  }
  @media (max-width: 767px) {
    min-height: 50vh;
  }
}
.dashes {
  position: absolute;
  width: 90%;
  height: 92%;
  // border: 2px dashed #4978de;
  text-align: center;
  line-height: 100px;
  background: linear-gradient(to right, #4978de 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#4978de 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #4978de 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#4978de 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 10px 1px, 1px 10px;
  border-radius: 5px;
  letter-spacing: 20px;
}

.dragdesc {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  color: #618be8;
  font-size: 18px;
  cursor: pointer;
  @media (max-width: 575px) {
    font-size: 16px;
  }
  p {
    margin-top: 30px;
  }
  &:hover {
    color: #4978de;
    svg {
      path {
        fill: #4978de;
      }
    }
  }
}
.green-button {
  background-color: #22c55e;
  color: white;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 4px;
}
.disabled-button {
  background-color: #b4b7c9;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 4px;
  color: white;
}
aside {
  z-index: 0;
  height: 100% !important;
}

.multipleinvoices {
  width: 100%;
  button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

select {
  font-family: "Lato";
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  width: 100%;
  height: 42px;
  display: block;
  gap: unset;
  border: 1px solid rgba(212, 215, 229, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  color: #ababab;
  margin-bottom: 15px;
  option {
    color: black;
  }
  .input-error {
    margin-left: 5px;
    margin-bottom: -10px;
    color: #f04343 !important;
    text-align: left;
  }
}

//recipient popup
.addrecipient {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  .popupshade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #101323cb;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: -1;
  }
}
.recipientPopup {
  padding: 20px 40px 40px 30px;
  border-radius: 15px;
  // min-height: 800px;
  height: 820px;
  overflow-y: scroll;
  background-color: white;
  margin: 10px auto;
  position: fixed;
  z-index: 1;
  .popupInformation {
    display: grid;
    grid-template-columns: 1fr;
    gap: 70px;
    width: 500px;
    @media (max-width: 991px) {
      gap: 40px;
    }
    .recipienttitle {
      margin-top: 0;
      color: #2b3677;
      font-weight: bold;
    }
    .field {
      margin-bottom: 20px;
      // width: 100%;
      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .error {
        margin-left: 10px;
      }
      &.rowfields {
        display: flex;
        gap: 10px;
        width: 100%;
        .company-recipientInput {
          width: 100%;
        }
        div {
          input {
            width: 180px;
          }
        }
        .columnInput {
          display: flex;
          flex-direction: column;
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
      #inputFieldUst {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="30"><text x="5" y="19" style="font: bold 16px Arial;">DE | </text></svg>')
          no-repeat;
        background-position: 5px 7.5px;
        text-indent: 35px;
      }
    }
    .topfield {
      margin-bottom: 20px;
      min-height: 100px;
      .recipientSwitch {
        display: flex;
        justify-content: center;
        align-items: center;
        .round-slider-container {
          width: 49px;
          height: 27px;
          .round-slider {
            width: 22px;
            height: 22px;
            top: 2.5px;
            right: 1px !important;
            left: inherit !important;
          }
        }
        .forcompanies {
          font-size: 14px;
          left: -110px;
          color: #b4b7c9;
          font-weight: bold;
          @media (max-width: 991px) {
            font-size: 12px;
            left: -88px;
          }
          &.activetab {
            color: #2b3677;
          }
        }
        .forinvestors {
          font-size: 14px;
          left: 65px;
          color: #b4b7c9;
          font-weight: bold;
          @media (max-width: 991px) {
            font-size: 12px;
          }
          &.activetab {
            color: #2b3677;
          }
        }
        #checkbox-input:checked + .round-slider-container .round-slider {
          transform: translateX(-24px);
        }
      }
    }
    .fieldtitle {
      color: #9397ad;
      font-weight: bold;
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
    .topfield {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .error {
        margin-left: 10px;
      }
    }
  }
}
@media (max-height: 900px) {
  .recipientPopup {
    padding: 10px 25px 20px 25px;
    height: 600px;
    min-height: 600px;
    .popupInformation {
      .topfield {
        min-height: 75px;
      }
    }
  }
}
@media (max-height: 685px) {
  .recipientPopup {
    height: 460px;
    min-height: 460px;
  }
}

.discardpopup {
  position: fixed;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  .popupshade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #101323cb;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: -1;
  }
  .discardchanges {
    display: flex;
    justify-content: center;
    margin-top: 195px;
  }
  .popupchanges {
    padding: 25px 35px 35px 35px;
    border-radius: 15px;
    overflow-y: scroll;
    background-color: white;
    margin: 10px auto;
    position: fixed;
    z-index: 9999;
    .popupHeader {
      position: relative;
      h3 {
        margin-top: 0;
      }
      span {
        position: absolute;
        top: -15px;
        right: -15px;
        cursor: pointer;
      }
    }
    h4 {
      margin-top: 0;
      color: rgb(163, 163, 163);
      font-weight: normal;
      margin-bottom: 30px;
    }
    .discardbuttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 20px;
      button {
        justify-content: center;
      }
    }
  }
}

.fieldinfo {
  display: flex;
  .infoicon {
    position: relative;
    margin-top: 30px;
    margin-left: 10px;
    cursor: pointer;
    .tooltip {
      position: absolute;
      width: max-content;
      font-weight: lighter;
      top: -57px;
      right: 4px;
      font-size: 10px;
      background-color: rgb(245, 245, 245);
      border-radius: 5px;
      padding: 5px;
      display: none;
      &.nospace {
        top: -45px;
      }
    }
    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}

.amountLabel {
  font-size: 14px !important;
  color: #2b3677 !important;
  margin-top: 0;
  font-weight: 300 !important;
}
.amountInput {
  position: relative;
  font-family: "Lato";
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  width: 100%;
  height: 100%;
  margin-bottom: -10px;
  // width: 251px;
  height: 42px;
  display: block;
  gap: unset;
  border: 1px solid rgba(212, 215, 229, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  &::placeholder {
    color: #ababab;
  }
}
.input-amountError {
  color: #ef4444a3 !important;
  text-align: left !important;
  font-size: 12px !important;
  font-weight: lighter !important;
  margin-bottom: 10px !important;
  margin-left: 5px !important;
}


@media(min-width: 2200px){
  .invoiceContainer .invoiceContent{
    grid-template-columns: 1fr 1fr;
    max-width: 77%;
    &.multipleFiles{
      grid-template-columns: 1fr 1fr;
    }
  }
  .pdfframe{
    width: 37vw !important;
  }
}

@media(min-width: 2900px){
  .invoiceContainer .invoiceContent{
    max-width: 70%;
    .mainRightCont{
      .fields-container{
        .top-fields{
          min-height: 95%;
          p{
            font-size: 20px;
          }
          select{
            height: 70px;
            font-size: 20px;
          }
        }
      }
      .rightBottomCont{
          .amountLabel{
            font-size: 23px !important;
            margin-bottom: 10px;
          }
          p{
            font-size: 20px !important;
            margin-bottom: 25px;
          }
          label{
            font-size: 23px;
            padding-bottom: 10px;
          }
          input{
            font-size: 20px;
            height: 70px;
          }
        }
        button{
          padding-top: 18px;
          padding-bottom: 18px;
          font-size: 25px;
        }
      }
    }
}