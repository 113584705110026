.faq{
    .faqHolder{
        padding: 10px 17px;
        width: 100%;
        min-height: 50px;
        box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.178);
        border-radius: 8px;
        margin-bottom: 20px;
        button{
            font-size: 16px;
            font-weight: 600;
            color: #0B0F19;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-left: 0;
            svg{
                margin-left: 15px;
            }
            &.active{
                svg{
                    margin-right: -11px;
                }
            }
        }
        P{
            margin-top: 0;
            color: #565973;
            font-size: 14px;
            line-height: 23px;
        }
    }
}