.container {
    background-color: #cedbf8;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        padding-top: 80px;
        padding-bottom: 60px;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 0.8fr;
        gap: 100px;
        max-width: 1355px;
        border-bottom: 3px solid #bbcaee;
    }
    h1 {
        font-family: "Lato-Black";
        font-size: 68px;
        line-height: 72px;
        color: #161d3e;
    }
    p {
        color: #2b3677;
        font-size: 18px;
        line-height: 22px;
    }
    button {
        margin-top: 30px;
        box-shadow: 0px 8px 18px -8px rgba(99, 102, 241, 0.9);
        border: 1.5px solid #2b3677;
        transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;

        &:hover {
            background-color: #cedbf8 !important;
            color: #2b3677;
            border: 1.5px solid #2b3677;
        }
    }
    &.jobsBanner{
        background-color: #F3F6FF;
        h1{
            color: #2B3677;
        }
    }
}

@media (max-width: 1499px) {
    .container {
        .content {
            padding: 50px 50px;
            grid-template-columns: 1fr 1fr;
            gap: 50px;
            h1 {
                font-size: 48px;
                line-height: 55px;
            }
        }
    }
}

@media (max-width: 991px) {
    .container {
        .content {
            h1 {
                font-size: 40px;
                line-height: 45px;
            }
        }
    }
}

@media (max-width: 767px) {
    .container {
        .content {
            gap: 30px;
            padding: 50px 30px;
            h1 {
                font-size: 35px;
                line-height: 40px;
            }
        }
    }
}

@media (max-width: 575px) {
    .container {
        .content {
            padding: 25px 30px 15px 30px;
            grid-template-columns: 1fr;
            gap: 0;
            h1 {
                margin-bottom: 0;
            }
        }
    }
}
