.header{
    width: 100%;
    background-color: #2b3677;
    padding: 14px 170px 10px 130px;

    min-height: 40px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2b3677;
    .link{
        margin-bottom: 10px;
    }
    @media(max-width: 1399px){
        padding: 20px 60px 20px 60px;
    }
    @media(max-width: 991px){
        padding: 20px 30px 20px 30px;
    }

    @media(max-width: 767px){
        padding: 20px 20px 20px 20px;
    }
    .menu{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    @media (max-width: 850px){
        flex-wrap:wrap;
    }
    .menu{
        @media (max-width: 850px){
            display:none;
        }
        li{
            padding: 3px 5px;
            &:hover{
                background-color: white;
                color: #2B3677;
                border-radius: 5px;
            }
        }
    }
    .mobile{
        z-index: 99;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        @media (min-width: 850px){
            display:none;
        }
       .links{
        button{
            svg{
                margin-right: 0;
            }
        }
       }
    }
    .links{
        margin-top: 0;
        display: flex;
        gap: 45px;
        list-style: none;
        color: white;
        font-size: 16px;
        align-items: center;
        button{
            margin-left: 10px;
            svg{
                margin-right: 5px;
                margin-left: 0;
            }
        }
        li{
           &.logo{
            &:hover{
                background-color: #2B3677;
            }
           }
        }
        &.rightLinks{
           gap: 10px;
           li{
            &:hover{
                background-color: #2B3677;
                color: white;
            }
        }
        }
        li{
            cursor: pointer;
        }
        @media(max-width: 991px){
            gap: 25px;
        }
        @media(max-width: 767px){
            margin-bottom: 0;
        }
        &.menu{
            justify-content: flex-start;
        }
    }
    .menuButton{
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 10px;
        svg{
            width: 25px;
            height: 25px;
            fill: white;
        }
    }
}


.sideMenu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2b3677;
    z-index: 1;
    color: white;
    padding: 20px;
    .closeButton{
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 10px;
        svg{
            width: 25px;
            height: 25px;
            fill: white;
        }
    }
    .menuList{
        list-style: none;
        margin-top: 20px;
        padding-left: 15px;
        .logo{
            margin-bottom:40px;
            margin-top: -13px;
            margin-left: -5px;
        }
        li{
            cursor: pointer;
            width: fit-content;
            font-size: 18px;
            margin-bottom: 15px;
            position: relative;
            &:after{
                content: "";
                border-radius: 5px;
                position: absolute;
                width: 0;
                height: 1.3px;
                background-color: rgb(171, 198, 255);
                bottom: 0;
                transition: .3s ease-in-out;
                left: 0;
            }
            &:hover{
                &::after{
                    transition: .3s ease-in-out;
                    width: 100%;
                }
            }
            &.activeBtn{
                &::after{
                    width: 100%;
                }
            }
        }
        button{
            font-size: 13px;
            padding: 5px 20px;

            svg{
                margin-left: 0;
                margin-right: 5px;
                width: 15px;
            }
        }
      ul{
        list-style: none;
        padding-left: 17px;
        li{
            font-size: 15px;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 30px;
            }
        }
      }
    }
}

  .menu{
    display: flex;
    li{
        &.activeLink{
            font-weight: bold;
        }
    }
    .authDropdown{
        position: relative;
        .signupDropdown{
            top: 50px;
            width: 87%;
            left: 15px;
            z-index: 9;
            position: absolute;
            background-color: white;
            color: #2B3677;
            padding: 5px 5px;
            border-radius: 5px;
            ul{
                padding-left: 0;
                list-style: none;
                font-size: 13px;
                li{
                    padding: 5px 8px;
                    &:hover{
                        background-color: #618ce841;
                        color: #2B3677;
                    }
                }
            }
        }
        &.aboutDropdown{
            .signupDropdown{
                left: 0;
                width: 152%;
                top: 38px;
            }
           li{
            display: flex;
            align-items: center;
            &.linkActive{
                svg{
                    transform: rotate(-180deg);
                    path{
                        fill: #2B3677 ;
                    }
                }
            }
            &:hover{
                svg{
                    path{
                        fill: #2B3677;
                    }
                }
            }
           }
            
        }
        li{
            button{
                padding: 9px 15px;
            }
            svg{
                margin-right: 0;
                path{
                    fill: white;
                }
            }
        &.linkActive{
            background-color: white;
            border-radius: 5px;
            color: #2B3677;
        }
    }
    }
  }

  .menu>li {
    cursor: pointer;
    font-size: 17px;
    position: relative;
  }

.linkHolder{
    display: flex;
    align-items: center;
    position: relative;
    &.linkActive{
        background-color:white ;
        color: #2B3677;
        border-radius: 5px;
        svg{
            transform: rotate(-180deg);
            path{
                fill: #2B3677 !important;
            }
        }
    }
    &:hover{
        svg{
            path{
                fill: #2B3677 !important; 
            }
        }
    }
    svg{
        path{
            fill: white !important;
        }
    }
}
.shade{
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
  .navDropdown{
    z-index: 1;
    left: 10%;
    margin-top: 8px;
    padding: 20px 40px 40px 40px;
    width: 46%;
    z-index: 10 !important;
    background-color: white;
    position: absolute;
    border-radius: 15px;
    h2{
        margin-top: 0;
        color: #2B3677;
        font-size: 30px;
        margin-bottom: 0;
    }
    p{
        margin-top: 15px;
        font-size: 16px;
        max-width: 600px;
        color: #2B3677;
    }
    .cardsHolder{
        margin-top: 70px;
        column-gap: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        row-gap: 50px;
        justify-content: center;
        width: 100%;
        .card{
            cursor: pointer;
            transition: box-shadow .3s;
            padding: 10px 20px 10px 20px;
            background-color:#F3F6FF;
            border-radius: 10px;
            &:hover{
                box-shadow: 0 0 11px #2b367758; 
            }
            &:last-child, &:nth-child(3){
                opacity: .7;
                &:hover{
                    box-shadow: none;
                    cursor: auto;
                }
            }
            &.active{
                box-shadow: 0 0 11px #2b36777b; 
            }
        }
        h1{
           margin-top: -50px;
           margin-bottom: 0;
           transform: scale(0.8);
           margin-left: -55px;
        }
        h3{
            margin-top: -15px;
            margin-bottom: 10px;
            font-size: 20px;
            color: #0B0F19;
        }
        p{
            margin-top: 0;
            font-size: 14px;
            color: #565973;
        }
    }
    @media(max-width: 1499px){
        width: 52%;
    }
    @media(max-width: 1299px){
        width: 57%;
    }
    @media(max-width: 1199px){
        transform: scale(0.8);
        width: 65%;
        top: 0;
        left: 0;
    }
    @media(max-width: 991px){
        transform: scale(0.7);
        width: 75%;
        top: -34px;
        left: -20px;
    }
    @media(max-width: 889px){
        transform: scale(0.7);
        width: 77%;
        top: -35px;
        left: 0px;
    }
  }
  .login{
    margin-left: -15px;
  }
.btnActive{
    svg{
        transform: rotate(-180deg);
    }
}

.noPageHeader{
    padding: 14px 170px 10px 130px;
    .links{
        margin-bottom: 5px;
    }
}

@media(max-width: 1199px){
    .header{
        padding: 20px 30px;
        .links{
            gap: 20px;
        }
    }
}

@media(max-width: 991px){
    .header{
        padding: 20px 10px;
        .links{
            gap: 10px;
            &.rightLinks{
                gap: 0;
            }
        }
    }
}

@media(max-width: 850px){
    .header{
        padding: 10px 30px;
        .links{
            gap: 10px;
            &.rightLinks{
                gap: 10px;
                display: flex;
                align-items: center;
                button{
                    svg{
                        margin-top: 7px;
                    }
                }
            }
        }
    }
}

.factoryheader{
    background-color: #cedbf8;
    border-bottom: none;
    .mobile{
        ul{
            li{
                color: #2B3677;
            }
        }
            svg{
                g{
                   path{
                    fill: #2B3677;
                   }
                }
                path{
               &:nth-child(2){
                    fill: #2B3677 ;
                }
               }
            }
            &:hover{
                background-color: #cedbf8;
            }
        }
    .menuButton{
        svg{
            path{
                &:nth-child(2){
                    fill: #2B3677;
                }
            }
        }
    }
    .links{
        &.rightLinks{
            li{
                &:hover{
                    background-color: #cedbf8;
                }
            }
            button{
                svg{
                            rect{
                            fill: #2B3677 !important;
                        }
                }
            }
        }
    }
    .menu{
        ul{
            li{
                color: #2B3677;
                &.linkHolder{
                    svg{
                        path{
                            fill: #2B3677 !important;
                        }
                    }
                }
                &.logo{
                    svg{
                        g{
                           path{
                            fill: #2B3677;
                           }
                        }
                        path{
                       &:nth-child(2){
                            fill: #2B3677 ;
                        }
                       }
                    }
                    &:hover{
                        background-color: #cedbf8;
                    }
                }
            }
        }
        .authDropdown{
            li{
                svg{
                    path{
                        fill: #2B3677 !important;
                    }
                }
            }
        }
    }
}

.factorymobile{
    background-color: #cedbf8;
    button{
        svg{
            path{
                stroke: #2B3677;
            }
        }
    }
}

.jobsHeader{
    padding-bottom: 0;
}