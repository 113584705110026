.content{
    ul{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        list-style: none;
        row-gap: 10px;
        li{
            color: #565973;
            font-size: 20px;
            line-height: 32px;
            position: relative;
            padding-left: 25px;
            span{
                color: #2B3677;
                font-weight: bold;
            }
            &::after{
                content: "";
                position: absolute;
                top: 10px;
                left: 0;
                background-image: url(../../../assets/icons/listtick.svg);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 14px;
                height: 12px;
            }
        }
    }
}

@media(max-width: 1299px){
    .container{
        h3{
            font-size: 20px;
        }
    }
}