.container{
    margin-bottom: 30px;
    padding-bottom: 30px;
    h3{
        color: #2B3677;
        font-size: 17px;
        margin-bottom: 0;
    }
    ul{
        list-style: none;
        padding-left: 0;
        li{
            font-size: 16px;
            color: #565973;
            padding-bottom: 5px;
        }
    }
    .list{
        display: flex;
        gap: 50px;
    }
}

@media(max-width: 991px){
    .container{
        margin-top: 20px;
    }
}

@media(max-width: 757px){
    .container{
        h3{
            font-size: 16px;
        }
        ul{
            li{
                font-size: 15px;
            }
        }
    }
}
