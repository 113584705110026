.cards{
    background-color: #F3F6FF;
    justify-content: center;
   padding: 50px 0;
   margin: 0 auto;
    h1{
        text-align: center;
        font-size: 40px;
        color: #0B0F19;
        font-weight: 900;
        font-family: "Lato-Black";
    }
    .content{
        display: flex;
        gap: 100px;
        margin: 0 auto;
        justify-content: center;
        margin-bottom: 70px;
        p{
            max-width: 330px;
            text-align: justify;
            color: #565973;
        }
    }
    .slider{
        margin-left: 80px;
        margin-right: 80px;
        @media(max-width: 1499px){
            margin-left: 60px;
            margin-right: 60px;
        }
        @media(max-width: 991px){
            margin-left: 30px;
            margin-right: 30px;
        }
        @media(max-width: 575px){
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .lastSection{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 20px;
    .companyPopup{
        justify-self: flex-end;
        max-width: 277px;
        background: rgba(43, 54, 119, 0.8);
        color: white;
        box-shadow: 0px 8px 18px -5px rgba(43, 54, 119, 0.8);
        border-radius: 10px;
        margin-top: 0px;
        padding: 10px 15px;
        h3{
            font-size: 16px;
        }
        p{
            font-size: 12px;
            font-weight: lighter;
        }
        a{
            font-size: 12px;
            display: flex;
            align-items: center;
            text-decoration: underline;
            color: white;
            &:hover{
                opacity: .7;
            }
        }
    }
    @media(max-width: 1499px){
        padding-right: 0px;
        .companyPopup{
        transform: scale(.8);
        }
    }
    @media(max-width: 991px){
       margin-right: -40px;
        .companyPopup{
        transform: scale(.8);
        }
    }
    @media(max-width: 767px){
        align-items: center;
        margin-right: 0;
        justify-content: center;
         .companyPopup{
            padding: 20px;
            transform: scale(1);
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-top: 80px;
        h3{
            text-align: center;
            font-size: 20px;
            margin-bottom: 0;
            }
            p{
                font-size: 14px;
                text-align: center;
            }
            a{
                justify-content: center;
                margin-bottom: 10px;
                font-size: 14px;
            }
         }
     }
     @media(max-width: 575px){
         .companyPopup{
        h3{
            text-align: left;
            font-size: 16px;
            }
            p{
                font-size: 12px;
                text-align: left;
            }
            a{
                font-size: 12px;
                justify-content: flex-start;
            }
         }
     }
    }
}

@media(max-width: 991px){
    .cards{
        padding: 40px 30px 70px 30px;
        h1{
            font-size: 30px;
            line-height: 35px;
            text-align: center;
        }
        .content{
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;
            flex-direction: column;
            align-items: center;
            gap: 0;
            p{
                max-width: 600px;
                margin-bottom: 0;
                margin-top: 10px;
            }
        }
    }
}

@media(max-width: 767px){
    .cards{
        h1{
            font-size: 25px;
            line-height: 30px;
        }
    }
}
