.textHolder{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .smallText{
        h1{
            font-size: 64px;
            margin-bottom: 0;
            color: #0B0F19;
        }
        span{
            font-size: 16px;
            color: #565973;
        }
    }
}

@media(max-width: 991px){
    .textHolder{
        justify-content: space-evenly;
        margin-bottom: 70px;
    }
}

@media(max-width: 575px){
    .textHolder{
        justify-content: space-between;
        margin-bottom: 70px;
    }
}
