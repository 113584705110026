.loginWrapper{
    position: fixed;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
   .loginPopup{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    min-width: 500px;
    padding-bottom: 20px;
    border-radius: 4px;
    .forgotPassword{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        background-color: white;
        position: absolute;
        border-radius: 4px;
        .logtitle{
            margin-top: 150px;
            text-align: center;
        }
        .inputs{
            .inputHolder{
                margin-top: 20px;
            }
        }
    }
    .headerlogin{
        z-index: 1;
        position: relative;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        width: 100%;
        min-height: 95px;
        background-color: #2B3677;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logtitle{
        font-size: 20px;
        font-weight: bold;
        color: #2B3677;
        margin-bottom: 0;
        margin-top: 40px;
    }
    .logdesc{
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        color: #98A6AD;
        max-width: 440px;
    }
   }
   .inputs{
    margin-top: 20px;
    .inputHolder{
    position: relative;
     margin-bottom: 30px;
     .error{
        font-size: 12px;
        color: rgba(255, 0, 0, 0.854) ;
      }
    }
   }
   .accDesc{
    font-size: 15px;
    color: #98A6AD;
    a{
        margin-left: 5px;
        text-decoration: none;
        font-weight: bold;
        color: #98A6AD;
    }
   }
   .checkboxHolder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
   .logincheck{
    font-size: 10px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    background-color: #618ce8c6;
    align-self: flex-start;
    margin-left: 10%;
    svg{
        margin-left: 4px;
    }
   }
   p{
    margin-top: 0;
    margin-left: 5px;
    color: #6c757ddf;
    font-weight: normal;
    font-size: 14px;
   }
}
}

.showPassword{
    position: absolute;
    top: 36px;
    right: 8px;
    svg{
        width: 13px;
        height: auto;
        g{
            fill: #6c757ddf;
        }
        path{
            stroke:#6c757ddf ;
        }
    }
}

.forgot{
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 0;
    font-size: 12px;
    color: #98A6AD;
}

@media(max-width: 991px){
    .loginWrapper{
        .accDesc{
            font-size: 12px;
        }
        .checkboxHolder{
            p{
                font-size: 12px;
            }
            .logincheck{
                width: 15px;
                height: 15px;
                margin-left: 5%;
                svg{
                    margin-left: 2px;
                }
            }
        }
        .loginPopup{
            min-width: 330px;
            padding-bottom: 5px;
            .forgotPassword{
                .logtitle{
                    margin-top: 80px;
                }
                .inputHolder{
                    margin-top: 45px;
                    .loginInput{
                        label{
                            font-size: 13px;
                        }
                        input{
                            width: 300px;
                            height: 40px;
                            &::placeholder{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            .logtitle{
                margin-top: 10px;
               font-size: 15px;
            }
            .logdesc{
                margin-top: 10px;
                font-size: 13px;
                line-height: 15px;
            }
            .headerlogin{
                height: 55px;
                min-height: 55px;
                justify-content: space-between;
                svg{
                    margin-left: 20px;
                    max-width: 100px;
                    width: 100%;
                }
            }
            .inputs{
                margin-top: 7px;
                .inputHolder{
                    margin-bottom: 15px;
                    .loginInput{
                        label{
                            font-size: 13px;
                        }
                        input{
                            width: 300px;
                            height: 40px;
                            &::placeholder{
                                font-size: 12px;
                            }
                        }
                    }
                    .eyebtn{
                        top: 30px;
                    }
                }
            }
            .loginbtn{
                padding: 5px 25px;
                font-size: 13px;
            }
        }
    }
}