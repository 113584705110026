@font-face {
    font-family: 'Lato';
    /*Can be any text*/
    src: local('Lato'), url('./fonts/Lato-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Lato-Bold';
    /*Can be any text*/
    src: local('Lato'), url('./fonts/Lato-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Lato-Black';
    /*Can be any text*/
    src: local('Lato'), url('./fonts/Lato-Black.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Lato-Italic';
    /*Can be any text*/
    src: local('Lato'), url('./fonts/Lato-Italic.ttf') format('truetype');
  }
  