@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.rpv-core__page-layer {
  width: 100% !important;
  height: 100% !important;
}
.rpv-core__canvas-layer {
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker__navigation-icon--next::before {
  left: 100px;
}

.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  padding: 5px !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
}
.react-datepicker__input-container input {
  width: 100% !important;
  padding: 5px !important;
  border-radius: 8px !important;
  height: 42px;
  margin-bottom: 15px;
  border: 1px solid #d4d7e5 !important;
}
.react-datepicker__input-container ::placeholder {
  color: #ababab !important;
}
.react-datepicker__navigation-icon--next {
  left: 108px !important;
}

.profiledate {
  margin-top: 0px !important;
  margin-bottom: 0 !important;
}
.react-datepicker__navigation-icon--previous::before {
  right: 100px !important;
}
canvas {
  width: 100% !important;
  height: 100% !important;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.rpv-core__text-layer {
  background-color: white;
  padding-left: 20px;
  padding-bottom: 20px;
}
.slick-slide img {
  width: 100% !important;
}
.rpv-core__inner-pages {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.box .slick-prev:before {
  opacity: 1;
  font-size: 28px;
  color: white;
}

.box .slick-next {
  left: 10px;
  top: -20%;
}

.box .slick-prev {
  left: -35px;
  top: -20%;
  color: rgba(214, 214, 214, 0.129);
}

/* .logos .slick-slide {
  width: 260px !important;
} */

.box .slick-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.box .slick-next:before {
  opacity: 1;
  font-size: 28px;
  color: white;
}
.slick-dots li button:before {
  font-size: 13px !important;
  color: #2b3677 !important;
  margin-top: 20px !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  margin-bottom: 20px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #d4d7e5 !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #d4d7e5;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9.5px 14px;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.6375em !important;
}
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d4d7e5 !important;
  border: 1px solid #d4d7e5 !important;
}

.css-1bn53lx {
  height: 42px !important;
}

.profiledatepicker {
  display: flex !important;
  flex-direction: column !important;
  /* margin-bottom: 30px !important; */
  margin-top: 3px;
}

.css-i44wyl {
  display: block !important;
}
.css-1bn53lx {
  width: 100% !important;
  margin-bottom: 18px !important;
}
#CookiebotWidget .CookiebotWidget-logo svg circle {
  fill: #2b3677 !important;
}

@media (min-width: 2900px) {
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 70px !important;
    margin-top: 10px !important;
  }
}

.summarySlider .slick-dots {
  bottom: 80px;
}

@media (max-width: 575px) {
  .summarySlider .slick-dots {
    bottom: 40px;
  }
}
@media (max-width: 575px) {
  .css-1v4ccyo.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #d4d7e5 !important;
  }
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 70px !important;
    margin-top: 10px !important;
  }
  .css-i44wyl {
    display: block !important;
  }
  .css-1bn53lx {
    width: 100% !important;
    margin-bottom: 18px !important;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border: 1px solid #d4d7e5 !important;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
    border-radius: 8px !important;
  }
  .MuiOutlinedInput-notchedOutline
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    height: 42px !important;
  }
  .MuiFormControl-root
    .MuiTextField-root
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    height: 42px !important;
  }
}
