.container{
    padding: 50px 100px;
    margin: 0 auto;
    .desc{
        margin-top: 70px;
        padding: 0 80px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 70px;
        p{
            color: #565973;
        }
    }
    h1{
        color: #0B0F19;
        font-size: 40px;
        line-height: 45px;
        font-family: "Lato-Black";
        text-align: center;
    }
    .months{
        margin-top: 80px;
        display: grid;
        align-items: center;
        justify-content: center;
        gap: 80px;
        grid-template-columns: repeat(4, 1fr);
        div{
            &:last-child{
                opacity: .5;
                div{
                &::after{
                    display: none;
                }
            }
            }
        }
    }
}

@media(max-width: 1499px){
    .container{
        .months{
            gap: 30px;
        }
    }
}

@media(max-width: 991px){
    .container{
        .months{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media(max-width: 767px){
    .container{
        padding: 20px 30px;
        h1{
            font-size: 30px;
        }
    }
}

@media(max-width: 575px){
    .container{
        padding: 20px 20px;
        h1{
            font-size: 20px;
            text-align: center;
        }
        .months{
            margin-top: 40px;
        }
    }
}

