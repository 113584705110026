.card{
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 20px 25px;
    max-width: 850px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
    .firstPart{
        display: flex;
        align-items: center;
        gap: 20px;
        img{
            max-width: 40px;
        }
    .date{
        display: flex;
        flex-direction: column;
        span{
            color: #565973;
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 0;
        }
        p{
            color: #0B0F19;
            font-weight: bold;
            margin-bottom: 0;
            margin-top: 0;
            display: flex;
            align-items: center;
        }
        .primary{
            color: white;
            padding: 5px 8px;
            font-size: 12px;
            background-color: #22C55E;
            border-radius: 5px;
            margin-left: 10px;
        }
    }
}
.secondPart{
    display: flex;
    align-items: center;
    gap: 10px;
}
.dateNumber{
    span{
        padding: 0 3px;
    }
}
}

.deleteModal {
    margin-top: 0 !important;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #101323b8;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  
    .shades {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      background-color: transparent;
    }
    .modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        max-width: 570px;
        width: 100%;
        height: auto;
        border-radius: 8px;
        padding: 30px 25px; 
        z-index: 2;
        p{
            font-size: 17px;
            margin-top: 30px;
        }
        svg{
            margin-top: 30px;
            path{
                fill: #2B3677;
            }
        }
        .btns{
            margin-top: 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 35px;
            gap: 20px;
            button{
                padding: 0;
            }
        }
    }
  }