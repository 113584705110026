.optionsHolder {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 14px;
    color: #2b3677;
    font-weight: 700;
  }
}
.chartInfos {
  background-color: #f9f9fd;
  width: 100%;
  display: flex;
  justify-content: space-around;
  //   margin: 65px 0;
  .compContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 50%;
    p {
      color: #98a6ad;
      font-size: 14px;
    }
    .components {
      display: flex;
      justify-content: space-between;
      width: 80%;
      padding: 20px;
      .individualComp {
        display: flex;
        flex-direction: column;
        text-align: center;
        line-height: 3px;
        margin-top: -30px;
        .value {
          display: flex;
          align-items: center;
          margin-bottom: -20px;
          span {
            font-size: 25px;
          }
          #bullet1 {
            color: #0693da;
          }
          #bullet2 {
            color: #0dc9a0;
          }
          #bullet3 {
            color: #ec7600;
          }
          #bullet4 {
            color: #8e00ec;
          }
          p {
            font-size: 30px;
            font-weight: 400;
            color: #919ca7;
          }
        }
        p {
          font-size: 14px;
          color: #98a6ad;
        }
      }
    }
  }
}

.recharts-wrapper {
  padding-top: 30px;
  background: white;
}

.chartwrapper {
  margin-top: 25px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
