.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f3f6ff;
    padding: 30px 20px 70px 20px ;
    h1{
        text-align: left;
        align-self: center;
        width: 80%;
        justify-self: center;
        font-size: 42px;
        font-family: "Lato-Black";
        line-height: 45px;
        color: #2B3677;
    }
    .cardsHolder {
        margin-top: 70px;
        column-gap: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        row-gap: 80px;
        justify-content: center;
        width: 80%;
        .card {
            cursor: pointer;
            transition: box-shadow 0.3s;
            padding: 25px 20px;
            background: #ffffff;
            box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
            border-radius: 8px;
            min-height: 200px;
            &:hover {
                box-shadow: 0 0 11px #2b367758;
            }
        }
        h1 {
            margin-top: -65px;
            margin-bottom: 0;
            transform: scale(0.8);
            margin-left: -55px;
        }
        h3 {
            margin-top: -15px;
            margin-bottom: 10px;
            font-size: 20px;
            color: #0b0f19;
        }
        p {
            margin-top: 0;
            font-size: 14px;
            color: #565973;
        }
    }
}

@media(max-width: 1299px){
  .container{
    h1{
        width: 90%;
    }
    .cardsHolder{
        width: 90%;
        column-gap: 50px;
    }
  }
}

@media(max-width: 991px){
    .container{
        h1{
            width: 95%;
            font-size: 25px;
        }
      .cardsHolder{
          width: 95%;
          column-gap: 30px;
          .card{
            min-height: 250px;
          }
      }
    }
  }

  @media(max-width: 767px){
    .container{
        h1{
            width: 80%;
        }
      .cardsHolder{
          width: 80%;
          column-gap: 30px;
          grid-template-columns: 1fr;
          .card{
            min-height: 150px;
          }
      }
    }
  }

  @media(max-width: 575px){
    .container{
        h1{
            width: 98%;
            padding-left: 10px;
        }
      .cardsHolder{
          width: 100%;
          .card{
            h1{
                margin-left: -40px;
            }
          }
      }
    }
  }