.mission {
    position: relative;
    z-index: 1;
    background-color: white;
    min-height: 40vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 80px 170px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .content {
        max-width: 1380px;
        margin: 0 auto;
        span {
            color: white;
            opacity: 0.7;
            font-size: 24px;
            font-weight: bold;
        }
        h2 {
            color: white;
            font-weight: bold;
            font-size: 48px;
            max-width: 800px;
            line-height: 55px;
            font-family: "Lato-Black";
        }
        ul{
            list-style-type: none;
            padding-left: 0;
            max-width: 600px;
            li{
                position: relative;
                padding-left: 30px;
                font-size: 16px;
                line-height: 25px;
                color: white;
                margin-bottom: 15px;
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-image: url(../../../assets/icons/listarrow.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        button{
            margin-top: 50px;
            padding: 9px 50px;
        }
        button{
            border: 1.5px solid #2B3677;
            transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;
        
            &:hover{
                background-color: transparent !important;
                border: 1.5px solid rgb(97, 139, 232);
            }
        }
    }
}

@media(max-width: 1199px){
    .mission{
        grid-template-columns: 1fr 0.3fr;
    }
}


@media(max-width: 991px){
    .mission{
        grid-template-columns: 1fr;
        .content{
            span{
                font-size: 20px;
            }
            h2{
                margin-top: 10px;
                font-size: 30px;
                max-width: 500px;
            }
        }
    }
}

@media(max-width: 767px){
    .mission{
        padding: 30px 30px;
        .content{
            span{
                font-size: 18px;
            }
            h2{
                margin-top: 10px;
                font-size: 25px;
                line-height: 30px;
                max-width: 100%;
                margin-bottom: 30px;
            }
            button{
                margin: 20px auto;
            }
        }
    }
}