.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 100px 90px 100px;
  h3 {
    color: #0b0f19;
    font-family: "Lato-Black";
    font-size: 30px;
    margin-bottom: 20px;
  }
  p {
    color: #565973;
    font-size: 16px;
    max-width: 500px;
    text-align: center;
  }
  span {
    font-size: 20px;
    color: #2b3677;
    margin-bottom: 30px;
  }
  .cards {
    margin-top: 20px;
    width: 85%;
  }
}

@media (max-width: 1499px) {
  .container {
    .cards {
      width: 80%;
    }
  }
}
@media (max-width: 1299px) {
  .container {
    .cards {
      width: 100%;
    }
  }
}

@media (max-width: 1099px) {
  .container {
    padding: 50px;
  }
}

@media (max-width: 991px) {
  .container {
    padding: 50px 50px 70px 50px;
    h3 {
      font-size: 23px;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      max-width: 400px;
    }
    span {
      font-size: 15px;
    }
    .cards {
      margin-top: 20px;
      display: block;
    }
  }
}
