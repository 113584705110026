.factoryBanner{
    h1{
        font-size: 50px;
        color: #161d3e;
        font-weight: bolder;
        font-family: "Lato-Black";
    }
    .cards{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }
    .content{
        width: 80%;
    }
}
@media(max-width: 991px){
    .factoryBanner{
        .content{
            width: 100%;
        }
    }
}
@media(max-width: 575px){
    .factoryBanner{
        .cards{
            grid-template-columns: 1fr;
            gap: 5px;
        }
    }
}