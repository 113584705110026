.investorMarketplace {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-color: #d0ddf9;
  .innerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .topElements {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin: 40px 0 20px 0;
      margin-bottom: -5px;
      @media(min-width: 1900px){
        width: 77%;
      }
      h2 {
        font-size: 24px;
        font-weight: bold;
        color: #2b3677;
      }
      .buttonsHolder {
        display: flex;
        align-items: center;
        gap: 20px;
        button {
          &:nth-child(1) {
           padding: 9px 30px;
          }
          text-align: center;
          height: 35px;
          padding: 9px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 18px;
          }
        }
      }
    }
    .firstSec {
      width: 77%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .investorcards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 35px;
      }
      .profileHolder {
        border-radius: 5px;
        padding: 20px;
        background-color: white;
        width: 55%;
        height: auto;
        .investorinfo {
          display: flex;
          justify-content: space-between;
          .information {
            margin-top: 20px;
            margin-left: 10px;
            p {
              margin-top: -15px;
              color: #b4b7c9;
              font-size: 16px;
              margin-bottom: 0;
            }
            h2 {
              margin-top: 30px;
              color: #919ca7;
              font-size: 22px;
              font-weight: bold;
              margin-bottom: 20px;
            }
          }
          .profileinfo {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #c1dbe3;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            // opacity: 25%;
            cursor: pointer;
            img {
              width: 15px;
            }
          }
        }
        .balanceTitle {
          color: #98a6ad;
          margin-bottom: 10px;
        }
        .balanceContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .balanceItem {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-top: -35px;
            svg {
              width: 50px;
              height: auto;
              margin-top: 30px;
            }
            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                margin-bottom: -15px;
                color: #919ca7;
                font-size: 13px;
              }
              .balanceAmount {
                font-size: 13px;
              }
            }
            &:nth-child(2) {
              //   margin-right: 50px;
              svg {
                width: 40px;
                margin-right: 5px;
              }
            }
          }
          button {
            text-align: center;
            display: flex;
            justify-content: center;
            width: 140px;
            height: 35px;
            margin-left: 10px;
          }
        }
      }
    }
    .secondSec {
      width: 77%;
      background-color: white;
      min-height: 500px;
      margin-top: 20px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .infoSec {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        padding: 20px 40px;
        justify-content: space-between;
        p {
          color: #2b3677;
          font-weight: bold;
          &:nth-child(2) {
            color: #618be8;
            font-weight: 500;
            cursor: pointer;
            img {
              margin-bottom: -5px;
            }
          }
        }
      }
      .tabs {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 0 40px;
        button {
          transition: 0.2s ease;
          margin-right: 20px;
        }
      }
      .marketTable {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        margin-bottom: -20px;
        margin-top: 20px;
        padding: 0 40px;
        .marketTableHead {
          background-color: #eff2fc;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          height: 60px !important;
          overflow-y: hidden;
          width: 100%;
          th{
            &:first-child{
              width: 6%;
            }
            &:nth-child(2){
              width: 3%;
            }
            &:nth-child(3){
              width: 9%;
            }
            &:nth-child(4){
              width: 4%;
            }
            &:nth-child(5){
              width: 6%;
            }
            &:nth-child(6){
              width: 3%;
            }
            &:nth-child(7){
              width: 5%;
            }
           }
          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #bdbbbb;
            border-radius: 10px;
          }
          th {
            white-space: nowrap;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            user-select: none;
            font-size: 11px;
            color: #33354d;
            img {
              width: 15px;
            }
            &:nth-child(1) {
              margin-left: 10px;
              @media(max-width: 899px){
                margin-left: 17px;
                font-size: 10px;
              }
            }
          }
        }
        tbody {
          width: 100%;
          max-height: 500px;
          overflow-y: auto;
          tr {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            margin-bottom: 10px;
            text-align: center;
            margin-top: 10px;
            td {
              font-size: 12px;
              color: #919ca7;
              min-width: 100px;
              @media(max-width: 1024px){
                min-width: 93px;
              }
              &:nth-child(1) {
                margin-left: -1%;
                width: 6%;
              }
              &:nth-child(2) {
                color: #22c55e;
                margin-left: 3.5%;
                width: 3%;
              }
              &:nth-child(3) {
                max-width: 80px;
                margin-left: 5%;
                text-align: left;
                width: 9%;
              }
              &:nth-child(4) {
                margin-left: 3.7%;
                text-align: center;
                width: 4%;
                @media(max-width: 1099px){
                  margin-left: 2%;
                }
                @media(max-width: 1024px){
                  margin-left: 1%;
                }
              }
              &:nth-child(5) {
                margin-left: 5%;
                text-align: left;
                width: 3%;
              }
              &:nth-child(6) {
                margin-left: 4.7%;
                text-align: left;
                width: 3%;
              }
              &:nth-child(7) {
                width: 5%;
              }
              span {
                min-width: 50px;
                padding: 4px;
                border-radius: 5px;
              }
              .LOW {
                background-color: #cbf5db;
                color: #22c55e;
              }
              .MEDIUM {
                background-color: #ffefc7;
                color: #ffba08;
              }
              .HIGH {
                background-color: #ebc7c7;
                color: #ef4444;
              }
              button {
                font-size: 11px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-self: center;
                margin-left: 32%;
              }
            }
          }
          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #bdbbbb;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

//laptop
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .investorMarketplace {
    .innerContent {
      .topElements {
        width: 77%;
        display: flex;
        justify-content: space-between;
        @media(max-width: 1399px){
          gap: 0;
        }
      }
      .firstSec {
        .investorcards {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 25px;
          .investorbox {
            width: 240px !important;
            &:nth-child(2) {
              margin-right: 25px;
            }
          }
        }
      }
      .secondSec {
        .marketTable {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          margin: 0 auto;
          margin-bottom: -20px;
          margin-top: 20px;
          padding: 0 40px;
          overflow-x: scroll;
          .marketTableHead {
            overflow-x: scroll;
            height: 50px;
            th {
              margin-right: 80px;
            }
          }
        }
      }
    }
  }
}
//tablet
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .investorMarketplace {
    .innerContent {
      .topElements {
        gap: 275px;
      }
      .firstSec {
        width: 100%;
        flex-direction: column;
        margin-top: 20px;
        .investorcards {
          margin-bottom: 25px;
          gap: 25px;
          .investorbox {
            width: 440px !important;
          }
        }
        .profileHolder {
          width: 88%;
        }
      }
      .secondSec {
        width: 88%;
        .tabs {
          width: 95%;
          padding: 0 25px;
          button {
            margin-right: 10px;
          }
        }
        .marketTable {
          padding: 0 40px;
          .marketTableHead {
            overflow-x: scroll;
            height: 50px;
            th {
              margin-right: 70px;
            }
          }
        }
      }
    }
  }
}
//mobile
@media screen and (max-width: 767px) {
  .investorMarketplace {
    .innerContent {
      .topElements {
        align-items: center;
        margin-bottom: 20px;
        h2 {
          margin-bottom: -700px;
        }
      }
      .firstSec {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .investorcards {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 40px;
          .investorbox {
            width: 350px !important;
          }
        }
        .profileHolder {
          width: 90%;
          .investorinfo {
            .information {
              h2 {
                margin-left: 80%;
              }
            }
          }
          .balanceContainer {
            flex-direction: column;
            align-items: center;
            .balanceItem {
              margin-bottom: 20px;
            }
          }
        }
      }
      .secondSec {
        width: 90%;
        .tabs {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          button {
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
        .marketTable {
          .marketTableHead {
            height: 50px;
            overflow-x: scroll;
            th {
              margin-right: 72px;
            }
          }
        }
      }
    }
  }
}


@media(max-width: 609px){
  .investorMarketplace{
    .innerContent{
      .secondSec{
        .marketTable{
          .marketTableHead{
            th{
              margin-right: 82px;
              &:nth-child(1){
                margin-left: 9px;
              }
            }
          }
        }
      }
    }
  }
}

@media(min-width: 1499px){
  .investorMarketplace{
    .innerContent{
      .secondSec{
        .marketTable{
          .marketTableHead{
             th{
              margin-right: 120px;
              &:first-child{
                width: 1%;
              }
              &:nth-child(2){
                width: 1%;
              }
              &:nth-child(3){
                width: 4%;
              }
              &:nth-child(4){
                width: 1%;
              }
              &:nth-child(5){
                width: 2%;
              }
              &:nth-child(6){
                width: 2%;
              }
              &:nth-child(7){
                width: 2%;
              }
             }
              
          }
          tbody{
            tr{
              td{
                &:nth-child(4){
                  width: 12%;
                }
                &:nth-child(4){
                  width: 15%;
                }
              }
            }
          }
        }
      }
    }
  }
}