.cardHolder{
    display: flex;
    align-items: center;
    justify-content: center;
.card{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 40px;
    z-index: 1;
    .imgHolder{
        width: 100%;
        height: 100%;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: #0B0F19;
            opacity: .6;
            border-radius: 10px;
            width: 0;
            height: 100%;
        }
        &.disabledImg{
            &:after{
                width: 100%;
            }
        }
    }
    .disabled{
        margin-top: 65px;
        display: flex;
        align-self: center;
        justify-self: center;
        position: absolute;
        font-size: 40px;
        color: white;
        opacity: .8;
        @media(max-width: 1699px){
            margin-top: 55px;
        }
        @media(max-width: 1599px){
            margin-top: 45px;
        }
        @media(max-width: 1499px){
            margin-top: 75px;
        }
        @media(max-width: 1299px){
            margin-top: 100px;
        }
        @media(max-width: 1199px){
            margin-top: 80px;
        }
        @media(max-width: 1099px){
            margin-top: 65px;
        }
        @media(max-width: 991px){
            margin-top: 75px;
        }
        @media(max-width: 899px){
            margin-top: 70px;
            font-size: 30px;
        }
        @media(max-width: 767px){
            margin-top: 50px;
        }
        @media(max-width: 655px){
            margin-top: 50px;
            font-size: 25px;
        }
        @media(max-width: 575px){
            margin-top: 100px;
            font-size: 30px;
        }
        @media(max-width: 480px){
            margin-top: 80px;
        }
        @media(max-width: 399px){
            font-size: 25px;
            margin-top: 60px;
        }
    }
    .content{
        h4{
            font-size: 24px;
            color: #0B0F19;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        ul{
            list-style-type: none;
            padding-left: 0;
            max-width: 600px;
            li{
                position: relative;
                padding-left: 30px;
                font-size: 16px;
                line-height: 25px;
                color: #565973;
                font-weight: lighter;
                margin-bottom: 10px;
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-image: url(../../../assets/icons/listarrow.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        a{
            color: #618BE8;
            svg{
                transform: translateX(5px);
                transition: .3s ease-in-out;
                path{
                    fill: #618BE8;
                }
            }
            &:hover{
                svg{
                    transform: translateX(10px);
                    transition: .3s ease-in-out;
                }
            }
        }
        &.disabledContent{
            opacity: .6;
        }
    }
}
}

@media(max-width: 991px){
    .cardHolder{
        margin-right: 10px;
        .card{
            padding-left: 0;
            width: 100%;
            .content{
                h4{
                    font-size: 18px;
                    margin-top: 20px;
                }
            }
        }
    }
}


