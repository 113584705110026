.container {
    margin: 30px auto 0 auto;
    max-width: 89%;
    .paymentlist {
        display: grid;
        grid-template-columns: 0.8fr 1fr;
        div {
            &:nth-child(n + 3) {
                border-bottom: 1px solid #2B3677;
            }
            &:nth-child(n + 1){
                border-bottom: 1px solid #2B3677;
            }
            &:last-child, &:nth-child(5){
                border: none;
            }
        }
    }
}

@media(max-width: 991px){
    .container{
        margin-bottom: 10px;
        .paymentlist{
            grid-template-columns: 1fr 1fr;
            gap: 0px;
        }
    }
}


@media(max-width: 660px){
    .container{
        margin-bottom: 10px;
        .paymentlist{
            grid-template-columns: 1fr;
            gap: 0px;
            div{
                &:nth-child(5){
                    border-bottom: 1px solid #2B3677;
                }
            }
        }
    }
}