.signupInvestor {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    form{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .inputsPart {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        .investorInput{
            max-width: 400px;
            width: 100%;
            position: relative;
        }
        .eyebtn{
            top: 10px;
        }
        .navSignup {
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-self: flex-start;
            ul {
                display: flex;
                list-style: none;
                margin-left: 90px;
                li {
                    cursor: pointer;
                    font-family: "Lato";
                    font-size: 16px;
                    font-weight: 700;
                    color: rgb(16, 19, 35);
                    margin-left: 10px;
                    margin-right: 10px;
                    &:nth-child(2) {
                        margin-left: 2px;
                        margin-right: 2px;
                    }
                }
            }
            svg {
                max-width: 70%;
                width: 100%;
            }
        }
        .goHome {
            display: flex;
            align-items: center;
            position: absolute;
            top: 32px;
            margin-top: 0;
            left: 20px;
            color: #2b3677;
            svg {
                transform: translateX(-2px);
                transition: 0.1s ease-in-out;
            }
            &:hover {
                svg {
                    transform: translateX(-7px);
                    transition: 0.1s ease-in-out;
                }
            }
        }
        button {
            margin-top: 30px;
        }
        h1 {
            font-size: 35px;
            color: #2b3677;
            max-width: 400px;
            width: 100%;
            margin-bottom: 50px;
            line-height: 40px;
            margin-top: 100px;
        }
        button{
            margin-bottom: 20px;
        }
    }
    .backgroundSignup {
        background-image: url(../../assets/images/investorsignup.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }
    .error{
        font-size: 12px;
    }
}

@media (max-width: 991px) {
    .signupInvestor {
        grid-template-columns: 1fr;
        .backgroundSignup {
          opacity: 0;
        }
        .inputsPart {
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            h1{
                margin-top: 70px;
                font-size: 30px;
                line-height: 32px;
                margin-bottom: 40px;
            }
            .goHome {
                top: 0px;
                font-size: 11px;
            }
            .navSignup {
                padding-left: 0;
                padding-right: 0;
                margin-top: 30px;
                justify-content: space-between;
                svg {
                    width: auto;
                }
                ul {
                    margin-left: 70px;
                }
            }
        }
    }
}


@media(max-width: 1399px){
    .signupInvestor{
        .inputsPart{
            .goHome{
                top: 5px;
                font-size: 12px;
            }
            .navSignup{
                margin-top: 30px;
            }
        }
    }
}

.verifyInvestor{
    label{
        font-size: 20px;
        text-align: center;
    }
}