.MainDiv{
    width: 100%;
    background-color: white;
    height: 76vh;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    @media(max-width: 991px){
        height: 55vh;
    }
    @media(max-width: 767px){
        height: 48vh;
    }
    .container{
        position: absolute;
        top: 10px;
        left: 10px;
    }
    :where(.css-dev-only-do-not-override-1i536d8).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
        border: none !important;
        left: 0;
    }
    .ant-upload.ant-upload-select{
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 52vw !important;
        height: 67vh !important;
        background-color: transparent !important;
        border: none !important;
        @media(max-width: 1199px){
            height: 55vh !important;
            width: 87vw !important;
        }
        @media(max-width: 767px){
            height: 46vh !important;
        }
    }
}
.uploadbtn{
    color: #618BE8;
    max-width: 500px;
    font-weight: 500;
    font-family: "Lato" !important;
    font-size: 18px;
    @media(max-width: 1000px){
        max-width: 350px;
    }
    @media(max-width: 575px){
        font-size: 16px;
    }
}

:where(.css-dev-only-do-not-override-1hyej8k).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover{
    border: none !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
}

:where(.css-dev-only-do-not-override-1hyej8k).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    display: none !important;
}
:where(.css-dev-only-do-not-override-1hyej8k).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn-sm{
    margin-left: 35px !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover{
    &:first-child{
        a{
        display: none !important;
        }
    }
}

:where(.css-dev-only-do-not-override-acm2ia).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn-sm{
    margin-left: 65px !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container{
    width: 155px !important;
    height: 125px !important;
}
:where(.css-dev-only-do-not-override-acm2ia).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-thumbnail .anticon {
    font-size: 40px !important;
    margin-top: 17px !important;
}


@media(min-width: 2200px){
    .MainDiv{
        height: 78vh;
    .ant-upload.ant-upload-select{
        width: 37vw !important;
    }
  }
  .uploadbtn{
    font-size: 22px;
    margin-top: 50px;
  }
  .multipleinvoices button{
    font-size: 23px;
  }
}
  

@media(min-width: 2900px){
    .MainDiv{
    .ant-upload.ant-upload-select{
        width: 34vw !important;
    }
  }
  .uploadbtn{
    font-size: 30px;
    max-width: 700px;
    margin-top: 50px;
  }
  .multipleinvoices button{
    font-size: 28px;
  }
}
