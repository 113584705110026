.container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 400px;
    h3{
        margin-bottom: 0;
        margin-top: 10px;
        color: #0B0F19;
        font-size: 20px;
    }
    p{
        margin-top: 7px;
        font-size: 16px;
        line-height: 20px;
        color: #565973;
    }
}
.faqCard{
    padding: 25px 20px;
    border: 1px solid #E2E5F1;
    border-radius: 8px;
    min-width: 180px;
    width: 100%;
    h3{
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    p{
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0;
    }
}

@media(max-width: 991px){
    .faqCard{
        align-items: center;
    }
}

.solutionsCard{
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
    border-right: 1.5px solid #e2e5f1;
    border-bottom: 1.5px solid #e2e5f1;
    max-width: 100%;
    padding-bottom: 70px;
    padding-top: 70px;
    margin-bottom: 0;
    transition: background-color 0.3s ease-out, border 0.3s ease-out;

    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

   .icon{
        border-radius: 8px;
        max-width: 65px;
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F3F6FF;
        box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.178);
             transition: transform 0.3s ease-out, border 0.3s ease-out;

   }

   h3{
    font-family: "Lato-Black";
    font-size: 20px;
   }
   p{
    max-width: 340px;
    color: #565973;
    line-height: 22px;
   }
   &:nth-child(4), &:nth-child(5), &:nth-child(6){
    border-bottom: none;
   }
   &:hover{
    background: rgba(97, 139, 232, 0.3);
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
    .icon{
        transform: scale(1.07);
    }
   }
   @media(max-width: 1299px){
    .icon{
        max-width: 50px;
        height: 50px;
        svg{
            transform: scale(0.7);
        }
    }
   }
   @media(max-width: 991px){
    &:nth-child(4){
        border-bottom: 1.5px solid #e2e5f1;
       }
   }
   @media(max-width: 767px){
    padding-top: 40px;
    padding-bottom: 40px;
    &:nth-child(5){
        border-bottom: 1.5px solid #e2e5f1;
       }
       .content{
        transform: scale(0.75);
       }
   }
   @media(max-width: 575px){
    padding-top: 10px;
    padding-bottom: 0px;
       .content{
        margin-top: -20px;
        transform: scale(0.65);
       }
       .icon{
        max-width: 40px;
        height: 40px;
        svg{
            transform: scale(0.6);
        }
       }
       h3{
        font-size: 18px;
        text-align: center;
       }
   }
}

