.container{
    padding: 70px 30px;
    max-width: 1350px;
    margin: 0 auto;
    h1{
        font-family: "Lato-Black";
        text-align: center;
        margin-bottom: 100px;
        color: #0B0F19;
        font-size: 40px;
    }
    .stepsHolder{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 50px;
    }
}

@media(max-width: 1299px){
    .container{
        h1{
            font-size: 30px;
            margin-bottom: 70px;
        }
    }
}
@media(max-width: 1199px){
    .container{
        .stepsHolder{
            gap: 30px;
        }
    }
}
@media(max-width: 991px){
    .container{
        padding: 30px;
        h1{
            margin-bottom: 50px;
            font-size: 25px;
        }
        .stepsHolder{
           grid-template-columns: 1fr 1fr;
        }
    }
}