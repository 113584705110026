.container{
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #2B3677 35.94%, #22294F 100%);
    p{
        font-size: 24px;
        color: white;
        margin-top: 0;
        margin-bottom: 0;
    }
    h1{
        margin-bottom: 0;
        margin-top: 0;
        font-size: 64px;
        color: white;
        font-family: "Lato-Black";
    }
    button{
        margin-top: 20px;
    }
}

@media(max-width: 767px){
    .container{
        p{
            font-size: 18px;
        }
        h1{
            font-size: 40px;
        }
        button{
            font-size: 14px;
            padding: 7px 18px;
        }
    }
}