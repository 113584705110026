.container{
    padding: 100px 150px;
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .members{
        margin-top: 50px;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }
    h1{
        font-size: 40px;
        line-height: 45px;
        font-family: "Lato-Black";
        text-align: left;
    }
}

@media(max-width: 1499px){
    .container{
        padding: 100px 100px;
    }
}

@media(max-width: 1299px){
    .container{
        padding: 100px 50px;
    }
}

@media(max-width: 991px){
    .container{
        padding: 70px 30px;
        .members{
            gap: 10px;
        }
    }
}

@media(max-width: 767px){
    .container{
        padding: 70px 30px;
        h1{
            font-size: 30px;
        }
        .members{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media(max-width: 575px){
    .container{
        padding: 20px 20px;
        h1{
            padding-left: 10px;
        }
        .members{
            margin-top: 10px;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}