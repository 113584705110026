.invest {
    padding: 50px 50px;
    z-index: 1;
    position: relative;
    background-color: white;
    margin-bottom: 170px;
    .investHeader {
        margin-bottom: 100px;
        h1 {
            font-size: 40px;
            font-weight: bolder;
            text-align: center;
            font-family: "Lato-Black";
            margin-bottom: 0;
        }
        p {
            font-size: 16px;
            color: #565973;
            text-align: center;
            margin-top: 5px;
        }
    }
    .investContent {
        display: flex;
        max-width: 900px;
        flex-direction: column;
        margin: 0 auto;
        .imgHolder {
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 90%;
        }

        .gifWrapper {
            z-index: 9999;
            position: relative;
            max-width: 800px;
            padding-left: 40px;
            padding-right: 40px;
            width: 100%;
            height: 100%;
        }

        img {
            padding-left: 40px;
            padding-right: 40px;
            position: absolute;
            max-width: 500px;
            width: 100%;
            max-width: 0 auto;
            display: flex;
            align-self: center;
            justify-self: center;
          &.vector{
            box-shadow: 0px 0px 25px rgba(154, 161, 171, 0.4);
            padding-top: 70px;
            padding-bottom: 70px;
            border-radius: 50%;
          }
            
        }
        .wrapper {
            display: flex;
            justify-content: space-between;
            position: relative;
            .iconText {
                position: absolute;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                &:nth-child(2) {
                    align-items: flex-start;
                }
                &.first{
                    top: 0px;
                    left: -70px;
                }
                &.second{
                    top: -15px;
                    right: -100px;
                    span{
                        text-align: left;
                    }
                }
                &.third{
                    bottom: -150px;
                    left: -200px;
                    text-align: right;
                }
                &.fourth{
                    bottom: -160px;
                    right: -180px;
                    span{
                        text-align: left;
                    }
                }
                &.moveLeft {
                    margin-right: -80px;
                }
                &.disabled {
                    opacity: 0.4;
                }
                &.moveRight {
                    margin-left: -130px;
                }
                p {
                    font-weight: bold;
                    font-size: 19px;
                    margin-bottom: 10px;
                }
                span {
                    max-width: 250px;
                    text-align: right;
                }
            }
        }
    }
}

@media (max-width: 1299px) {
    .invest {
        .investContent {
            .imgHolder {
                .gifWrapper {
                    max-width: 650px;
                    padding-left: 40px;
                    padding-right: 40px;
                }
                img {
                    max-width: 435px;
                    padding-left: 60px;
                    padding-right: 60px;
                    box-shadow: none;
                }
            }
            .wrapper{
                .iconText{
                    &.first{
                        left: 25px;
                    }
                    &.third{
                        left: 25px;
                    }
                    &.second{
                        right: 40px;
                        top: -10px;
                    }
                    &.fourth{
                        right: 40px;
                    }
                    span{
                        font-size: 14px;
                        padding-left: 55px;
                    }
                    p{
                        font-size: 17px;
                    }
                }
            }
        }
    }
}



@media (max-width: 1199px) {
    .invest {
        .investContent {
            .imgHolder {
                .gifWrapper {
                    max-width: 650px;
                    padding-left: 40px;
                    padding-right: 40px;
                }
                img {
                    max-width: 435px;
                    padding-left: 60px;
                    padding-right: 60px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .invest {
        .investHeader{
            h1{
                font-size: 30px;
            }
            p{
                font-size: 15px;
            }
        }
        .investContent {
            .imgHolder {
                .gifWrapper {
                    max-width: 500px;
                }
                img {
                    max-width: 350px;
                }
            }
            .wrapper{
                .iconText{
                    p{
                        font-size: 14px;
                    }
                    span{
                        font-size: 12px;
                        padding-left: 90px;
                    }
                }
            }
        }
    }
}

@media(max-width: 767px){
   .invest{
    padding: 25px 30px;
    .investHeader{
        margin-bottom: 50px;
        h1{
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 15px;
        }
    }
    .investContent{
        .iconText{
            max-width: 300px;
            display: flex !important;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin: 20px auto;
            p{
                font-size: 18px;
            }
        }
        .imgHolder{
            width: 100%;
            margin-bottom: 40px;
            img{
                padding-left: 0;
                padding-right: 0;
                position: relative;
                max-width: 500px;
            }
            .gifWrapper{
                display: none;
            }
        }
    }
   }
}