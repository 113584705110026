.container{
    background-color: #cedbf8;
    padding-top: 30px;
    .content{
        display: grid;
        grid-template-columns: 1fr 0.8fr;
        .children{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
        .imageHolder{
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    button{
        margin: 50px auto 0 auto;
        padding: 9px 60px;
        border: 1.5px solid #2B3677;
        transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;
    
        &:hover{
            background-color: white !important;
            color: #2B3677;
            border: 1.5px solid #2B3677;
        }
    }
}

.bannerChildren{
    .content{
        padding-top: 50px;
        gap: 50px;
        padding-bottom: 50px;
        .children
        {
            align-items: flex-end;
        }
    }
    @media(max-width: 1499px){
        .content{
            grid-template-columns: 1fr 0.7fr;
            gap: 0;
            .imageHolder{
                display: flex;
                align-items: center;
                img{
                    width: 115%;
                    height: auto;
                }
            }
            .children{
                align-items: center;
                h1{
                    font-size: 48px;
                }
            }
        }
    }
    @media(max-width: 991px){
        .content{
            padding-top: 0;
            grid-template-columns: 1fr;
            width: 100%;
            gap: 0;
            .imageHolder{
                align-items: flex-end;
                justify-content: flex-end;
                img{
                    width: 100%;
                }
            }
            .children{
                padding: 10px 50px;
                align-items: center;
                h1{
                    font-size: 45px;
                }
            }
        }
    }
    @media(max-width: 767px){
        padding-top: 15px;
        .content{
            .children{
                padding: 10px 30px;
                h1{
                    font-size: 35px;
                    margin-bottom: 50px;
                }
            }
        }
    }
    @media(max-width: 575px){
        .content{
            .children{
                h1{
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.dashboardSection{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 70px;
    .content{
       margin: 0 auto;
       max-width: 1430px;
        grid-template-columns: 0.7fr 1fr;
        grid-auto-flow: dense;
        direction: rtl;
        .children{
            text-align: left;
            h3{
                font-size: 32px;
                line-height: 36px;
                color: #161d3e;
            }
            ul{
                list-style: none;
                li{
                    position: relative;
                    padding-left: 27px;
                    color: #565973;
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 10px;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 3px;
                        left: 0;
                        width: 18px;
                        height: 18px;
                        background-image: url(../../../assets/icons/listicon.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
    @media(max-width: 991px){
        .content{
            padding: 10px 20px;
            .children{
                h3{
                    font-size: 25px;
                }
                ul{
                    li{
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }
            }
            .imageHolder{
                img{
                    height: 100%;
                }
            }
        }
    }
    @media(max-width: 767px){
        padding-top: 0;
        .content{
            grid-template-columns: 1fr;
            .children{
                margin: 0 auto;
                h3{
                    text-align: center;
                }
                ul{
                  margin: 0 auto;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
            }
        }
    }
    @media(max-width: 575px){
        .content{
            .children{
                h3{
                    text-align: left;
                    padding-left: 15px;
                }
                ul{
                    align-items: flex-end;
                justify-content: flex-end;
                padding-left: 15px;
                padding-right: 0;
                margin-bottom: 20px;
                }
            }
        }
    }
}

.companySection{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: white;
    .content{
        margin: 0 auto;
        max-width: 1330px;
         grid-template-columns: 1fr 1fr;
         .imageHolder{
            img{
                width: 83%;
            }
         }
         .children{
            max-width: 400px;
            h3{
                font-size: 40px;
                line-height: 45px;
                color: #161d3e;
            }
            p{
                font-size: 16px;
                color: #565973;
            }
         }
    }
    @media(max-width: 1499px){
        padding: 0;
        padding-bottom: 30px;
       .content{
        max-width: auto;
        padding: 60px 60px;
        .imageHolder{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: auto;
            }
        }
       }
    }
    @media(max-width: 991px){
       .content{
        max-width: auto;
        padding: 20px 60px;
        grid-template-columns: 1fr;
        .children{
            max-width: 100%;
            h3{
                font-size: 35px;
                line-height: 40px;
                text-align: center;
                margin-top: 0;
            }
            p{
                text-align: center;
            }
            h1{
                font-size: 50px;
            }
            span{
                font-size: 14px;
            }
        }
       }
    }
    @media(max-width: 575px){
        .content{
         max-width: auto;
         padding: 20px 30px;
         grid-template-columns: 1fr;
        .children{
            h3{
                text-align: left;
                font-size: 30px;
                line-height: 35px;
                margin-bottom: 20px;
             }
             p{
                text-align: left;
             }
             h1{
                font-size: 40px;
             }
        }
        }
     }
}

.infoSection{
    padding-bottom: 50px;
    .content{
        grid-template-columns: 1fr;
        .children{
            h3{
                font-size: 40px;
                text-align: center;
                line-height: 45px;
                color: #0B0F19;
                font-family: "Lato-Black";
            }
        }
        .imageHolder{
            margin: 0 auto;
            img{
                margin-top: 20px;
                max-width: 1200px;
            }
        }
    }
    @media(max-width: 1299px){
        .content{
            .children{
            h3{
                font-size: 30px;
                line-height: 38px;
            }
        }
        }
    }
    @media(max-width: 991px){
        .content{
            .children{
                h3{
                        font-size: 25px;
                        line-height: 30px;
                }
                ul{
                    row-gap: 0px;
                    li{
                        font-size: 16px;
                    }
                }
            }
            .imageHolder{
                img{
                    margin-top: 0;
                }
            }
        }
    }
    @media(max-width: 767px){
        .content{
            .children{
                ul{
                    padding-left: 0;
                    padding-right: 10px;
                    li{
                        padding-left: 18px;
                        font-size: 12px;
                        &::after{
                            transform: scale(0.5);
                        }
                    }
                }
            }
        }
    }
    @media(max-width: 575px){
        padding-top: 10px;
        padding-bottom: 35ox;
        .content{
            .children{
                ul{
                    grid-template-columns: repeat(2, 1fr);
                    padding-left: 10px;
                    align-items: center;
                    justify-content: center;
                    padding-right: 0;
                    cokumn-gap: 10px;
                    li{
                        margin-right: 5px;
                        padding-left: 18px;
                    }
                }
            }
        }
        button{
            margin: 30px auto 0 auto;
            padding: 6px 30px;
        }
    }
}