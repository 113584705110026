.button {
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 9px 30px;
  border-radius: 4px;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  svg {
    margin-left: 10px;
  }
}

.deletebtn {
  margin-top: 10px;
  font-size: 14px;
  padding: 7px 20px;
  font-weight: 500;
}
.editbtn {
  border: 1px solid #e2e5f1;
  padding: 7px 23px;
  svg {
    margin-right: 5px;
    margin-left: 0;
  }
}
.deletebutton {
  border: 1px solid #2b3677;
  padding: 7px 23px;
  svg {
    margin-right: 5px;
    margin-left: 0;
  }
}

.addpayment {
  margin-top: 25px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  svg {
    margin-right: 10px;
    margin-left: 0;
  }
}
.selectbtn {
  width: fit-content;
  border: 1px solid #2b3677;
  margin: 30px auto 0 auto;
}
