.container{
    display: grid;
    grid-template-columns: 0.17fr 0.9fr;
    gap: 70px;
    padding: 70px 170px;
    h1{
        font-family: "Lato-Black";
    }
    .reviews{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
    .arrows {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      button {
        font-size: 16px;
        padding: 10px 20px;
        border: none;
        background-color: #f2f2f2;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
}

@media(max-width: 1699px){
    .container{
        padding: 70px 120px;
    }
}

@media(max-width: 1499px){
    .container{
        padding: 30px 40px;
        grid-template-columns: 1fr;
        h1{
            font-size: 25px;
            text-align: center;
        }
    }
}

@media(max-width: 767px){
    .container{
        gap: 30px;
        padding: 15px 20px;
        h1{
            font-size: 20px;
            line-height: 22px;
        }
        .reviews{
            grid-template-columns: 1fr;
            margin: 0 auto;
            >div{
                margin-bottom: 20px;
            }
        }
    }
}