.footer{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 50px 20px;
    .subscribe{
        display: flex;
        width: 100%;
        .error{
            position: absolute;
            font-size: 12px;
            color: rgba(255, 0, 0, 0.509);
            padding-left: 12px;
            padding-top: 3px;
        }
        .inputs{
            width: 100%;
        }
    }
    ul{
        padding-left: 0;
        list-style: none;
        li{
            cursor: pointer;
            font-size: 16px;
            margin-bottom: 10px;
            color: #33354D;
        }
    }
    div{
    p{
        font-size: 16px;
        color: #33354D;
        &.address{
            max-width: 150px;
        }
    }
    span{
        color: #618BE8;
    }
    a{
        color: #2B3677;
        text-decoration: underline;
    }
    .inputHolder{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        input{
            max-width: 300px;
            width: 100%;
            padding: 8px 10px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid #D4D7E5;
        }
        button{
            border-top-left-radius: 0px;
            padding: 8px 25px;
            border-bottom-left-radius: 0px;
            font-weight: normal;
            font-size: 15px;
        }
    }
}
.icons{
    display: flex;
    align-items: center;
    gap: 13px;
    svg{
        transform: scale(1);
        &:first-child{
            transform: scale(1.3);
            margin-left: -5px;
        }
        &:hover{
            path{
                fill: #4f78d0;
            }
        }
    }
}
}

@media(max-width: 991px){
    .footer{
        display: grid;
        grid-template-columns: 1fr 0.6fr 0.6fr 0.6fr;
        gap: 40px;
        ul{
            li{
                font-size: 14px;
            }
        }
        div{
            p{
                font-size: 14px;
            }
            span{
                font-size: 14px;
            }
        }
    }
}
@media(max-width: 767px){
    .footer{
        grid-template-columns: 1fr 1fr 1fr;
        padding: 50px 40px;
        div{
            &:first-child{
                display: flex;
                align-items: center;
                flex-direction: column;
                grid-column: 1 / -1;
                .inputHolder{
                    input{
                        max-width: 350px;
                    }
                }
                .subscribe{
                    flex-direction: row;
                    justify-content: center;
                    .inputs{
                        width: 52%;
                        position: relative;
                        .error{
                            bottom: -22px;
                            left: 0;
                        }
                    }
                }
                }
        }
    }
}

@media(max-width: 575px){
    .footer{
        padding: 50px 30px;
        gap: 20px;
       div{
        .inputHolder{
            input{
                max-width: 100%;
            }
        }
    
       }
       div{
        &:first-child{
            .subscribe{
                flex-direction: row;
                justify-content: center;
                .inputs{
                    width: 82%;
                }
            }
            }
    }
    }
}