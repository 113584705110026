.container{
    background-color: #2B3677;
   padding: 50px 170px;
   min-height: 440px;
   margin-bottom: 400px;
    h1{
        color: white;
        margin-top: 0;
        font-size: 56px;
        line-height: 54px;
        font-family: "Lato-Black";
    }
    .images{
        padding-right: 20px;
        position: absolute;
        display: grid;
        grid-template-columns: 0.96fr 0.5fr 0.46fr;
        gap: 30px;
        img{
            width: 100%;
            height: auto;
            &:nth-child(2){
                margin-top: 40px;
            }
        }
    }
}

@media(max-width: 1499px){
    .container{
         padding: 50px 100px;
         margin-bottom: 350px;
    }
}

@media(max-width: 1299px){
    .container{
         padding: 50px 30px;
         margin-bottom: 270px;
    }
}
@media(max-width: 1099px){
    .container{
         margin-bottom: 200px;
    }
}

@media(max-width: 991px){
    .container{
         min-height: 300px;
         margin-bottom: 240px;
         h1{
            font-size: 35px;
         }
    }
}

@media(max-width: 845px){
    .container{
         margin-bottom: 200px;
    }
}

@media(max-width: 767px){
    .container{
        padding: 20px 30px;
         min-height: 200px;
        margin-bottom: 250px;
         h1{
            font-size: 35px;
         }
    }
}
@media(max-width: 650px){
    .container{
        margin-bottom: 200px;
    }
}
@media(max-width: 575px){
    .container{
        border-top: 1px solid #2b3677;
        padding: 20px 20px;
         min-height: 180px;
         h1{
            font-size: 30px;
         }
         .images{
            gap: 10px;
            img{
                &:nth-child(2){
                    margin-top: 10px;
                }
            }
         }
    }
}

@media(max-width: 499px){
    .container{
        margin-bottom: 140px;
    }
}

@media(max-width: 415px){
    .container{
        margin-bottom: 100px;
    }
}

@media(max-width: 390px){
    .container{
        margin-bottom: 80px;
    }
}