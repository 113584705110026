.container {
    padding: 50px 50px;
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    justify-content: center;
    gap: 60px;
    .faqInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 600px;
        margin: 0 auto;
        p {
            text-align: left;
            max-width: 300px;
        }
        .cards {
            margin-top: 30px;
            display: flex;
            gap: 40px;
        }
    }
    .faq {
        max-width: 720px;
    }
}

@media (max-width: 1099px) {
    .container {
        padding: 50px 30px;
        grid-template-columns: 0.5fr 1fr;
        .faqInfo {
            max-width: 400px;
        }
    }
}

@media (max-width: 991px) {
    .container {
        padding: 50px 30px;
        grid-template-columns: 1fr;
        gap: 30px;
        .faqInfo {
            max-width: 600px;
            align-items: center;
            h1 {
                font-size: 25px;
                line-height: 32px;
                text-align: center;
                margin-bottom: 0;
            }
            p {
                text-align: center;
            }
            .cards {
                display: none;
            }
        }
        .faq {
            margin: 0 auto;
            .cards {
                margin-top: 30px;
                display: flex;
                gap: 40px;
            }
        }
    }
}

@media(max-width: 575px){
    .container{
        padding: 30px;
        .faq{
            .cards{
                gap: 20px;
            }
        }
    }
}