.card{
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 40px;
    border-radius: 8px;
    max-width: 100%;
    min-height: 440px;
    color: #101323;
    border: 0.705247px solid #E2E5F1;
    box-shadow: 0px 3.10309px 8.46296px -0.705247px rgba(19, 16, 34, 0.06), 0px 1.41049px 4.51358px -0.705247px rgba(19, 16, 34, 0.03);
    background-color: #E2E5F1;
    h2, h1{
        font-family: "Lato-Black";
        text-align: center;
    }
    h1{
        font-size: 40px;
        margin-bottom: 0;
    }
    h2{
        margin-top: 5px;
        font-size: 25px;
    }
    .payment{
        display: flex;
        justify-content: space-between;
        text-align: left;
        ul{
            margin-top: 30px;
            padding-left: 0;
            list-style: none;
            li{
                padding-bottom: 10px;
            }
        }
    }
    &.secondCard{
        background-color: #4C5791;
        color: white;
    }
}

@media(max-width: 991px){
    .card{
        margin: 0 auto;
        max-width: 400px;
        min-height: 400px;
        display: block;
        h1{
            font-size: 30px;
        }
        h2{
            font-size: 16px
        }
        ul{
            li{
                font-size: 15px;
            }
        }
    }
}